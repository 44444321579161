import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatDate(dateString: string) {
  const date = new Date(dateString);

  if (!date) {
    throw new Error("Invalid date format");
  }

  const options = {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  } as const;

  return date.toLocaleDateString("en-GB", options).replace(",", "");
}

export const getFormattedURL = (url: string) => {
  let prefixes = ["https://www.", "http://www.", "https://", "http://"];
  let newUrl = url;
  prefixes.forEach((prefix) => {
    newUrl = newUrl.replaceAll(prefix, "");
  });
  if(newUrl[newUrl.length - 1] === '/') newUrl = newUrl.slice(0, -1) // remove last "/"
  return newUrl;
};

export const getPageLink = (url: string) => {
  return url.toLowerCase().replaceAll(' ', '-').replaceAll('é', 'e').replaceAll('è', 'e').replaceAll("&", "and")
}