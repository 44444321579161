import { AnimatePresence } from "framer-motion";
import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { LANGS } from "../constants";
import { PAGES } from "../constants/pages";
import SectionWrapper from "./hocs/SectionWrapper";
import Loader from "./ui/loader";

// Lazy-loaded components
const HomePage = React.lazy(() => import("./Home/HomePage"));
const MenuPage = React.lazy(() => import("./MenuPage/MenuPage"));
const BranchPage = React.lazy(() => import("./Branch/BranchPage"));
const MenuItem = React.lazy(() => import("./MenuItemPage"));
const Article = React.lazy(() => import("./Article"));
const Collaboration = React.lazy(
  () => import("./CollaborationsPage/CollaborationItem")
);
const Events = React.lazy(() => import("./Events"));
const Event = React.lazy(() => import("./Event"));
const Subscribe = React.lazy(() => import("./Subscribe"));
const CollaborationsPage = React.lazy(() => import("./CollaborationsPage"));
const InspirationsPage = React.lazy(() => import("./InspirationsPage"));
const Inspiration = React.lazy(() => import("./Inspiration"));

function AnimatedRoutes() {
  const location = useLocation();
  const { i18n } = useTranslation();

  function getRoutes() {
    return (
      <Suspense
        fallback={
          <SectionWrapper className="justify-center items-center">
            <Loader />
          </SectionWrapper>
        }
      >
        <Routes location={location} key={location.pathname}>
          <Route path={PAGES.HOME} Component={HomePage} />
          <Route path={PAGES.MENU} Component={MenuPage} />
          <Route path={PAGES.BRANCH} Component={BranchPage} />
          <Route
            path={PAGES.EVENTS}
            element={
              i18n.language === LANGS.FR ? (
                <Navigate to={PAGES.EVENTS_FR} replace />
              ) : (
                <Events />
              )
            }
          />
          <Route
            path={PAGES.EVENTS_FR}
            element={
              i18n.language === LANGS.NL || i18n.language === LANGS.DE ? (
                <Navigate to={PAGES.EVENTS} replace />
              ) : (
                <Events />
              )
            }
          />
          <Route path={`${PAGES.MENU}/:lang/:slug`} Component={MenuItem} />
          <Route path={`${PAGES.EVENT}/:lang/:slug`} Component={Event} />
          <Route path={`${PAGES.ARTICLE}/:lang/:slug`} Component={Article} />
          <Route
            path={PAGES.COLLABORATIONS}
            element={
              i18n.language === LANGS.FR ? (
                <Navigate to={PAGES.COLLABORATIONS_FR} replace />
              ) : (
                <CollaborationsPage />
              )
            }
          />
          <Route
            path={PAGES.COLLABORATIONS_FR}
            element={
              i18n.language === LANGS.NL || i18n.language === LANGS.DE ? (
                <Navigate to={PAGES.COLLABORATIONS} replace />
              ) : (
                <CollaborationsPage />
              )
            }
          />
          <Route
            path={PAGES.INSPIRATIONS}
            element={
              i18n.language === LANGS.FR ? (
                <Navigate to={PAGES.INSPIRATIONS_FR} replace />
              ) : (
                <InspirationsPage />
              )
            }
          />
          <Route
            path={PAGES.INSPIRATIONS_FR}
            element={
              i18n.language === LANGS.NL || i18n.language === LANGS.DE ? (
                <Navigate to={PAGES.INSPIRATIONS} replace />
              ) : (
                <InspirationsPage />
              )
            }
          />
          <Route
            path={`${PAGES.INSPIRATIONS}/:lang/:slug`}
            Component={Inspiration}
          />
          <Route
            path={`${PAGES.INSPIRATIONS_FR}/:lang/:slug`}
            Component={Inspiration}
          />
          <Route
            path={`${PAGES.COLLABORATIONS}/:lang/:slug`}
            Component={Collaboration}
          />
          <Route
            path={`${PAGES.COLLABORATIONS_FR}/:lang/:slug`}
            Component={Collaboration}
          />
          <Route path={`${PAGES.SUBSCRIBE}/:id`} Component={Subscribe} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    );
  }

  return <AnimatePresence mode={"wait"}>{getRoutes()}</AnimatePresence>;
}

export default AnimatedRoutes;
