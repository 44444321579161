import React from 'react';

const variantMap = {
  title: { tag: 'h1', className: 'text-h1 font-domain text-white font-normal text-[60px] ' },
  // h2: { tag: 'h2', className: 'text-h1 font-domain text-white font-normal text-[60px] ' },
  subtitle: { tag: 'h2', className: 'text-h2 font-matter text-white font-normal text-[36px]' },
  sectionTitle: { tag: 'h3', className: 'font-matter text-2xl font-medium' },
  menuTitle: {tag: 'h2', className: 'font-domain text-white font-normal text-[30px]'},
  h4: { tag: 'h4', className: 'font-matter text-lg font-semibold' },
  body: { tag: 'p', className: 'text-base font-matter' },
  smallText: { tag: 'span', className: 'text-lg text-black font-matter' },
  mediumText: {tag: 'span', className: 'text-lg text-black font-medium'},
  headerLink: {tag: 'span', className: 'text-headerLink text-black'},
  footerLink: {tag: 'span', className: 'text-footerLink'},
  menuSubtitle: {tag: 'h3', className: 'text-xl font-bold font-domain'}
} as const;

interface TypographyProps {
  variant?: keyof typeof variantMap;
  children: React.ReactNode;
  className?: string;
}

const Typography: React.FC<TypographyProps> = ({
  variant = 'body', // Default to 'body'
  children,
  className = '',
}) => {
  const { tag, className: baseStyle } = variantMap[variant];
  const Component = tag as keyof JSX.IntrinsicElements; // Ensures correct tag type for React.createElement

  return React.createElement(
    Component,
    { className: `${baseStyle} ${className}` },
    children
  );
};

export default Typography;