import "./App.scss";
import "./scss/fonts.css";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";

import { ApolloProvider } from "@apollo/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import client from "./api/apolloClient";
import AnimatedRoutes from "./Components/AnimatedRoutes";
import { Footer } from "./Components/Footer/Footer";
import { Header } from "./Components/Header/Header";
import ScrollManager from "./Components/ScrollManager";
import { Sidebar } from "./Components/Sidebar";
import AppProvider from "./providers/AppProvider";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={client}>
        <AppProvider>
          <HelmetProvider>
            <Helmet>
              {/* Google Tag Manager Script */}
              <script>
                {`
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-NVC5GCR');
                `}
              </script>
            </Helmet>
            <Router>
              <ScrollManager />
              <Header />
              <Sidebar />
              <main id="main" className={"main-content"}>
                <AnimatedRoutes />
                <Footer />
              </main>
              {/* Cutom cookies component */}
              {/* <Cookies /> */}
              {/* Google Tag Manager (noscript) */}
              <noscript>
                <iframe
                  src="https://www.googletagmanager.com/ns.html?id=GTM-NVC5GCR"
                  height="0"
                  width="0"
                  style={{ display: "none", visibility: "hidden" }}
                  title="GTM"
                ></iframe>
              </noscript>
            </Router>
          </HelmetProvider>
        </AppProvider>
      </ApolloProvider>
    </QueryClientProvider>
  );
}

export default App;
