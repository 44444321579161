import React, { createContext, useState } from "react";

interface AppContextType {
  isShowSearch: boolean;
  setIsShowSearch: (isShowSearch: boolean) => void;
  isShowMenu: boolean;
  setIsShowMenu: (isShowSearch: boolean) => void;
}

interface AppProviderProps {
  children: React.ReactNode;
}

const initialState = {
  isShowSearch: false,
  setIsShowSearch: () => {},
  isShowMenu: false,
  setIsShowMenu: () => {},
};

export const AppContext = createContext<AppContextType>(initialState);

function AppProvider({ children }: AppProviderProps) {
  const [isShowSearch, setIsShowSearch] = useState(false);
  const [isShowMenu, setIsShowMenu] = useState(false);
  return (
    <AppContext.Provider
      value={{ isShowSearch, setIsShowSearch, isShowMenu, setIsShowMenu }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default AppProvider;
