export const PAGES = {
  HOME: "/",
  MENU: "/menu",
  BRANCH: "/branch",
  AFTERCARE: "/aftercare",
  CONTACT: "/contact",
  SUBSCRIBE: "/subscribe",
  PARTNER: "/partner",
  ARTICLE: "/article",
  EVENT: "/event",
  CONDOLEANCES: "/condoleances",
  EVENTS: "/events",
  INSPIRATIONS: "/inspiraties",
  COLLABORATIONS: "/samenwerkingen",

  // NL PAGES
  PRIVACY: "/nl/privacybeleid",
  TERMS: "/nl/verkoopsvoorwaarden",
  ABOUT: "/over-ons",

  // FR PAGES
  EVENTS_FR: "/evenements",
  INSPIRATIONS_FR: "/inspirations",
  COLLABORATIONS_FR: "/collaborations",
  PRIVACY_FR: "/fr/declaration-de-confidentialites",
  TERMS_FR: "/fr/sereni-conditions-generales",
  ABOUT_FR: "/a-propos-de-sereni",

  //external PAGES
  MY_SERENI: "https://mijn.sereni.be/",
  JOBS: "https://jobs.sereni.be",
  JOBS_FR: "https://jobs.sereni.be/l/fr",
  JOBS_DE: "https://sereni-deutschland-gmbh.jobs.personio.de",
};
