import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const ScrollManager = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const mainContent = document.querySelector('#main')
    mainContent?.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollManager;
