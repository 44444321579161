import i18next from "i18next";

import { RootNode } from "@strapi/blocks-react-renderer/dist/BlocksRenderer";

import { GetContentQuery } from "../generated/graphql";

export const getSearchContent = (t: typeof i18next.t) => {
  return [
    {
      name: t("branch.branch"),
      keywords: [t("branch.branch")],
      path: "/branch",
    },
  ];
};

type ContentLinkType = { name?: string; slug?: string };

const searchInDescription = (
  description: RootNode[],
  searchString: string
): boolean => {
  if (!description || !searchString) return false;
  const lowerSearch = searchString.toLowerCase();

  const searchRecursive = (block: any): boolean => {
    if (!block) return false;

    // Check if block has text content
    if (
      typeof block === "string" &&
      block.toLowerCase().includes(lowerSearch)
    ) {
      return true;
    }

    // Check if block has children and recursively search them
    if (Array.isArray(block.children)) {
      return block.children.some(searchRecursive);
    }

    // If block has text field, check it
    if (
      block.text &&
      typeof block.text === "string" &&
      block.text.toLowerCase().includes(lowerSearch)
    ) {
      return true;
    }

    return false;
  };

  return Array.isArray(description)
    ? description.some(searchRecursive)
    : searchRecursive(description);
};

export const findMatchingPages = (
  content: GetContentQuery | undefined,
  search: string,
  lang: string
) => {
  const getContentLinks = (content: any, slug: string): ContentLinkType[] => {
    return content
      ?.filter((contentPage: any) => {
        let keywords = [contentPage?.title, contentPage?.name];
        return (
          keywords.join(" ").toLowerCase().indexOf(search.toLowerCase()) !==
            -1 || searchInDescription(contentPage?.description, search)
        );
      })
      .map((contentPage: any) => ({
        name: contentPage?.name ?? contentPage?.title,
        slug: `${slug}/${lang}/${contentPage?.slug}`,
      }));
  };

  let articles = content?.articles
    ? getContentLinks(content.articles, "/article")
    : [];
  let partners = content?.partners
    ? getContentLinks(content.partners, "/partner")
    : [];
  let menuPages = content?.menuPages
    ? getContentLinks(content.menuPages, "/menu")
    : [];
  let events = content?.events ? getContentLinks(content.events, "/event") : [];
  return [...articles, ...partners, ...menuPages, ...events];
};

export const getSortedList = (list: any[], field = "date") => {
  return list.sort((a, b) => {
    if (a[field] && !b[field]) return -1;
    if (!a[field] && b[field]) return 1;
    return 0;
  });
};
