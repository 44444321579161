import React from "react";
import { cn } from "../../../utils";

interface SlidePanelWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  showPanel: boolean;
}

function SlidePanelWrapper({
  children,
  className,
  showPanel,
}: SlidePanelWrapperProps) {
  return (
    <div
      className={cn(
        `fixed bottom-0 lg:top-[158px] h-[calc(100vh-53px)] lg:h-[calc(100vh-158px)] -left-0 lg:left-[155px] lg:bottom-0 w-[100vw] lg:w-[400px] flex flex-col bg-white/90 transition-all duration-300 ease-in-out transform z-[999] ${
          showPanel
            ? "translate-x-0 opacity-100"
            : "-translate-x-[100vw] opacity-0"
        }`,
        className
      )}
    >
      {children}
    </div>
  );
}

export default SlidePanelWrapper;
