import React, { HTMLProps } from "react";
import Typography from "../typography";
import { Link } from "react-router-dom";
import { PageUrlType } from "../../../utils/types";
import { cn } from "../../../utils";



interface BreadcrumpsProps {
  pages: PageUrlType[];
  className?: HTMLProps<HTMLElement>["className"];
  
}
// sereni academy
function Breadcrumps({ pages, className }: BreadcrumpsProps) {
  return (
    <div className={cn("flex items-start mb-10", className)}>
      {pages.map((page, index) => (
        <div key={index} className="flex ml-1">
          <Link
            className="text-black no-underline hover:underline mr-1 font-matter"
            to={page.url}
          >
            {page.name}
          </Link>{" "}
          {index !== pages.length - 1 ? "> " : ""}
        </div>
      ))}
    </div>
  );
}

export default Breadcrumps;
