import { CloseButton } from "react-bootstrap";
import SlidePanelWrapper from "../../hocs/SlidePanel";
import MenuBarContent from "./MenuBarContent";

interface MenuBarProps {
  isShowMenu: boolean;
  handleClose: () => void
}

function MenuBar({ isShowMenu, handleClose }: MenuBarProps) {
  return (
    <SlidePanelWrapper showPanel={isShowMenu} className="bg-white pb-20">
      <CloseButton
        className="absolute right-6 top-2.5 p-2.5 z-[99999]"
        onClick={handleClose}
      />
      <MenuBarContent />
    </SlidePanelWrapper>
  );
}

export default MenuBar;
