import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsInstagram, BsLinkedin } from "react-icons/bs";
import { FaFacebookSquare } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import DefaultLogo from "../../assets/images/icon.png";
import { PAGES } from "../../constants/pages";
import { useGetMenuPagesQuery } from "../../generated/graphql";
import { AppContext } from "../../providers/AppProvider";
import "../../scss/style.scss";
import { cn, getPageLink } from "../../utils";
import Typography from "../ui/typography";
import "./Footer.scss";

export function Footer() {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();

  const { isShowSearch, setIsShowSearch, setIsShowMenu } =
    useContext(AppContext);
  const [combinedPages, setCombinedPages] = useState<any[]>([]);
  const { data, error, loading } = useGetMenuPagesQuery({
    fetchPolicy: "cache-first",
    variables: {
      locale: i18n.language,
      filters: {
        showInFooter: {
          eq: true,
        },
      },
      pagination: {
        limit: -1,
      },
    },
  });

  let defaultPages = [
    {
      title: t("menu.funeral_insurance"),
      slug:
        i18n.language === "nl"
          ? "https://www.sereniuitvaartverzekering.be/"
          : "https://www.sereni-prevoyance.be/",
    },
    {
      title: t("footer.inspiration"),
      slug: `${PAGES.INSPIRATIONS}`,
      onClick: () => {
        if (isShowSearch) {
          setIsShowSearch(false);
        }
        setIsShowMenu(true);
      },
    },
    {
      title: t("footer.obituaries"),
      slug: getPageLink(`${PAGES.MENU}/${i18n.language}${PAGES.CONDOLEANCES}`),
    },
    {
      title: t("footer.why_sereni"),
      slug: getPageLink(`${PAGES.MENU}/${i18n.language}/${t("menu.about_us")}`),
    },

    {
      title: t("footer.working_with_sereni"),
      slug: getPageLink(`${PAGES.MENU}/${i18n.language}${PAGES.JOBS}`),
    },
    {
      title: t("footer.events"),
      slug: PAGES.EVENTS,
    },
  ];

  const sortedMenuPages = useMemo(() => {
    return (
      data?.menuPages &&
      [...data?.menuPages]?.sort((a, b) => {
        return (
          (a?.showInFooterPriority ?? Infinity) -
          (b?.showInFooterPriority ?? Infinity)
        );
      })
    );
  }, [data]);

  useEffect(() => {
    if (!data || !sortedMenuPages) return;
    const combinedArray: any[] = [];
    sortedMenuPages.forEach((page) => {
      if (page?.showInFooterPriority)
        combinedArray[page.showInFooterPriority - 1] = page;
    });

    // Fill gaps with items from otherItems
    let otherIndex = 0;
    for (let i = 0; i < combinedArray.length; i++) {
      if (!combinedArray[i] && otherIndex < defaultPages.length) {
        combinedArray[i] = defaultPages[otherIndex++];
      }
    }

    // Add remaining otherItems if any
    while (otherIndex < defaultPages.length) {
      combinedArray.push(defaultPages[otherIndex++]);
    }

    setCombinedPages(combinedArray);
  }, [data]);

  return (
    <footer className="footer pb-4">
      <div
        className={cn(
          "border-t border-black mb-3 w-full",
          pathname === "/" ? "lg:w-[calc(100%-70px)]" : "lg:w-[calc(100%-16px)]"
        )}
      />
      <div className="footer-grid">
        <div className="flex flex-col md:flex-row items-center w-10/12 2xl:w-8/12">
          <div className="md:w-[173px] mb-4 md:mb-0">
            <Link to="/">
              <img
                className="w-[26px] h-[52px]"
                src={DefaultLogo}
                alt={"sereni logo"}
              ></img>
            </Link>
          </div>
          <div className={"f-links"}>
            <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-1 mb-[16px] lg:mb-0">
              {combinedPages &&
                combinedPages.map((menuPage, index) => (
                  <React.Fragment key={index}>
                    {menuPage?.onClick ? (
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          menuPage.onClick();
                        }}
                        className="cursor-pointer hover:underline"
                      >
                        <Typography variant="footerLink">
                          {menuPage?.title}
                        </Typography>
                      </a>
                    ) : (
                      <Link
                        to={
                          menuPage?.showInFooterPriority
                            ? `${PAGES.MENU}/${i18n.language}/${menuPage?.slug}`
                            : menuPage?.slug ?? "/"
                        }
                        target={`${
                          menuPage?.slug?.includes("http") ? "_blank" : "_self"
                        }`}
                      >
                        <Typography variant="footerLink">
                          {menuPage?.title}
                        </Typography>
                      </Link>
                    )}
                  </React.Fragment>
                ))}
            </div>
          </div>
        </div>

        <div className={"f-icons"}>
          <Link
            to={"https://www.facebook.com/sereni.uwuitvaartplanner/"}
            target={"_blank"}
            className={"footer-icons"}
          >
            <FaFacebookSquare size={22} />
          </Link>
          {/* <Link to={""} target={"_blank"} className={"footer-icons"}>
            <FaTwitter size={22} />
          </Link> */}
          <Link
            to={"https://www.instagram.com/sereni_uitvaartplanner/"}
            target={"_blank"}
            className={"footer-icons"}
          >
            <BsInstagram size={22} />
          </Link>
          <Link
            to={"https://nl.linkedin.com/company/sereni-uitvaartplanner"}
            target={"_blank"}
            className={"footer-icons"}
          >
            <BsLinkedin size={22} />
          </Link>
        </div>
      </div>
    </footer>
  );
}
