import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { VscAccount, VscSearch, VscMenu } from "react-icons/vsc";
import { IoLocationOutline } from "react-icons/io5";
import { PAGES } from "../../constants/pages";
import { Button } from "../ui/button";
import { SearchBar } from "./SearchBar";
import MenuBar from "./MenuBar";
import { AppContext } from "../../providers/AppProvider";
import "./Sidebar.scss";

export function Sidebar() {
  const navigate = useNavigate();
  const { isShowSearch, setIsShowSearch, isShowMenu, setIsShowMenu } =
    useContext(AppContext);
  // const [isSpeedDialOpen, setIsSpeedDialOpen] = useState(false);
  const location = useLocation();

  const toggleSearchModal = useCallback(() => {
    setIsShowSearch(!isShowSearch);
  }, [isShowSearch]);

  const toggleMenuModal = useCallback(() => {
    setIsShowMenu(!isShowMenu);
  }, [isShowMenu]);

  useEffect(() => {
    if (isShowSearch) setIsShowSearch(false);
    if (isShowMenu) setIsShowMenu(false);
  }, [location.pathname]);

  const menuItems = useMemo(
    () => [
      {
        icon: <VscSearch className="h-7 w-7" />,
        onClick: () => {
          toggleSearchModal();
          if (isShowMenu) toggleMenuModal();
        },
        isActive: isShowSearch,
      },
      {
        icon: <VscMenu className="h-7 w-7" />,
        onClick: () => {
          toggleMenuModal();
          if (isShowSearch) toggleSearchModal();
        },
        isActive: isShowMenu,
      },
      {
        icon: <IoLocationOutline className="h-8 w-8" />,
        onClick: () => {
          navigate(PAGES.BRANCH);
        },
        isActive: location.pathname.indexOf(PAGES.BRANCH) !== -1,
      },
      {
        icon: <VscAccount strokeWidth={0} className="h-7 w-7" />,
        onClick: () => {
          window.open(PAGES.MY_SERENI, "_blank");
        },
        isActive: false, //location.pathname.indexOf(PAGES.MY_SERENI) !== -1,
      },
    ],
    [location, isShowSearch, isShowMenu]
  );

  return (
    <>
      {/* Speed Dial for smaller screens */}
      {/* Speed Dial Actions */}
      {/* <div
        className={`flex fixed lg:hidden  bottom-6 right-6 z-[99999] flex-col items-center gap-4 transition-all duration-500 ease-out`}
      >
        <div
          className={cn(
            "flex flex-col gap-4 absolute",
            isSpeedDialOpen ? "bottom-24" : "-bottom-[400px]"
          )}
        >
          {menuItems.map((item, index) => (
            <button
              key={index.toString()}
              onClick={() => {
                item.onClick();
                setIsSpeedDialOpen(false);
              }}
              className="bg-white p-3 rounded-full shadow-lg hover:bg-gray-200 text-primary-500 transform transition duration-300"
              style={{
                transitionDelay: `${index * 100}ms`, // Staggered delay
                transform: isSpeedDialOpen
                  ? "translateY(0)"
                  : `translateY(${10 * (index + 1)}px)`,
                opacity: isSpeedDialOpen ? 1 : 0,
              }}
              // title={item.label}
            >
              {item.icon}
            </button>
          ))}
        </div>
        <button
          onClick={() => setIsSpeedDialOpen(!isSpeedDialOpen)}
          className="bg-white p-4 rounded-full shadow-md hover:bg-gray-200 transition-transform transform duration-300 ease-out"
        >
          {isSpeedDialOpen ? <FaTimes size={24} /> : <TiThMenu size={24} />}
        </button>
      </div> */}

      <aside className="hidden lg:flex fixed flex-col justify-start h-full bg-white pt-[130px] w-[155px] z-[99]">
        <div className="h-5/6 pt-10 border-0 flex flex-col justify-between relative w-full">
          <div
            className={
              "h-[40%] d-flex justify-between flex-column align-items-center mt-10"
            }
          >
            {menuItems.map((item, index) => (
              <Button
                variant="ghost"
                key={index.toString()}
                className={`w-12 h-12 p-0 ${
                  item.isActive && "bg-grey rounded-lg"
                }`}
                onClick={item.onClick}
              >
                {item.icon}
              </Button>
            ))}
          </div>
        </div>
      </aside>
      <SearchBar
        isShowSearch={isShowSearch}
        toggleSearchModal={toggleSearchModal}
        isShowMenu={isShowMenu}
        toggleMenuModal={toggleMenuModal}
      />
      <MenuBar isShowMenu={isShowMenu} handleClose={toggleMenuModal} />
    </>
  );
}
