import { useState } from "react";
import { useTranslation } from "react-i18next";

import { APP_LOCALES } from "../../i18n";
import { cn } from "../../utils";
import { Button } from "../ui/button";
import Typography from "../ui/typography";

export function LanguagesList() {
  const [isOpen, setIsOpen] = useState(false);
  const languages: any = APP_LOCALES;

  const { i18n } = useTranslation();

  function handleLanguageChange(lng: any) {
    i18n.changeLanguage(lng).then((r) => {
      const e = new CustomEvent("changeLanguage", {
        detail: { language: lng },
      });
      document.dispatchEvent(e);
    });
  }

  return (
    <>
      <div className="flex items-center lg:hidden">
        {languages.map((lang: any, index: any) => (
          <div key={lang} className="flex items-center">
            <Button
              onClick={() => handleLanguageChange(lang)}
              variant="ghost"
              className="px-2"
            >
              <Typography
                variant="mediumText"
                className={cn(
                  "uppercase",
                  i18n.language === lang &&
                    "font-semibold underline underline-offset-4 decoration-2"
                )}
              >
                {lang}
              </Typography>
            </Button>
            {index !== languages.length - 1 && (
              <Typography variant="mediumText">|</Typography>
            )}
          </div>
        ))}
      </div>
      <div className="relative hidden lg:block">
        <Button
          onClick={() => setIsOpen(!isOpen)}
          variant="ghost"
          className="w-10 px-2"
        >
          <Typography
            variant="mediumText"
            className={cn(
              "uppercase font-semibold underline underline-offset-4 decoration-2"
            )}
          >
            {i18n.language}
          </Typography>
        </Button>
        {/* Dropdown */}
        {isOpen && (
          <div className="absolute z-10 top-10 right-2 w-20 bg-white border rounded-md shadow-lg">
            {languages.map((lang: string) => (
              <button
                key={lang}
                onClick={() => {
                  handleLanguageChange(lang);
                  setIsOpen(false); // Close dropdown after selection
                }}
                className={cn(
                  "block px-4 py-2 text-left w-full hover:bg-gray-100 uppercase cursor-pointer",
                  i18n.language === lang && "font-semibold"
                )}
              >
                {lang}
              </button>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
