import React, { HTMLProps, useEffect, useRef, useState } from "react";
import { cn } from "../../../../utils";
import Breadcrumps from "../../../ui/breadcrumps";
import { PageUrlType } from "../../../../utils/types";

interface SectionWrapperContentProps
  extends React.HTMLAttributes<HTMLDivElement> {
  breadcrumps?: PageUrlType[];
  enableDivider?: boolean;
  children: React.ReactNode;
  headerContent?: React.ReactNode;
  breadcrumpsWrapperClassName?: HTMLProps<HTMLElement>["className"];
  wrapperClassName?: HTMLProps<HTMLElement>["className"];
}

function SectionWrapperContent({
  children,
  breadcrumps,
  enableDivider = true,
  className,
  headerContent,
  breadcrumpsWrapperClassName,
  wrapperClassName,
}: SectionWrapperContentProps) {
  const scrollableDivRef = useRef<HTMLDivElement>(null);
  const [showArrow, setShowArrow] = useState(false);

  const handleScrollToTop = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleScroll = () => {
    if (scrollableDivRef.current) {
      setShowArrow(scrollableDivRef.current.scrollTop > 0);
    }
  };

  useEffect(() => {
    const scrollableDiv = scrollableDivRef.current;

    if (scrollableDiv) {
      scrollableDiv.addEventListener("scroll", handleScroll);

      return () => {
        scrollableDiv.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <div className={cn("w-full xl:w-7/12 relative", wrapperClassName)}>
      {breadcrumps && (
        <div className={cn("mt-3 px-3", breadcrumpsWrapperClassName)}>
          <Breadcrumps pages={breadcrumps} />
          <div
            className={cn(
              "w-full h-[1px] mb-2",
              enableDivider ? "bg-gray-300" : "bg-white"
            )}
          ></div>
        </div>
      )}
      {headerContent}
      {showArrow && (
        <button
          className="absolute right-12 bottom-16 text-3xl bg-grey h-12 w-12 rounded-full flex items-center justify-center z-[9999]"
          onClick={handleScrollToTop}
        >
          ↑
        </button>
      )}
      <div
        ref={scrollableDivRef}
        className={cn(
          "xl:pr-10 lg:overflow-y-scroll px-3 pt-10 pb-0 lg:pb-20",
          breadcrumps ? "max-h-[85%]" : "max-h-[95%]",
          className
        )}
      >
        {children}
      </div>
    </div>
  );
}

export default SectionWrapperContent;
