import React from "react";

import { cn } from "../../../utils";

const Loader: React.FC = ({ className }: { className?: string }) => {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <div
        className={cn(
          "size-10 border-4 border-brown border-t-transparent rounded-full animate-spin",
          className
        )}
      ></div>
    </div>
  );
};

export default Loader;
