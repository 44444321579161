import { useCallback, useContext, useMemo, useState } from "react";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { VscSearch } from "react-icons/vsc";
import { Link, useLocation } from "react-router-dom";

import FullLogo from "../../assets/images/SereniFullLogo.png";
import { LANGS } from "../../constants";
import { PAGES } from "../../constants/pages";
import { useGetMenuPagesQuery } from "../../generated/graphql";
import { AppContext } from "../../providers/AppProvider";
import { cn } from "../../utils";
import { Button } from "../ui/button";
import Typography from "../ui/typography";
import { LanguagesList } from "./LanguagesList";

export function Header() {
  const { t, i18n } = useTranslation();
  const { isShowSearch, setIsShowSearch, isShowMenu, setIsShowMenu } =
    useContext(AppContext);
  const { data } = useGetMenuPagesQuery({
    fetchPolicy: "cache-first",
    variables: {
      locale: i18n.language,
      filters: {
        showInHeader: {
          eq: true,
        },
      },
      pagination: {
        limit: -1,
      },
    },
  });

  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleSearchModal = useCallback(() => {
    setIsShowSearch(!isShowSearch);
  }, [isShowSearch]);

  const toggleMenuModal = useCallback(() => {
    setIsShowMenu(!isShowMenu);
  }, [isShowMenu]);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const sortedMenuPages = useMemo(() => {
    return (
      data?.menuPages &&
      [...data?.menuPages]?.sort((a, b) => {
        return (
          (a?.showInHeaderPriority ?? Infinity) -
          (b?.showInHeaderPriority ?? Infinity)
        );
      })
    );
  }, [data]);

  const mobileLinks = [
    {
      link: PAGES.EVENTS,
      label: t("menu.events"),
    },
    {
      link: PAGES.INSPIRATIONS,
      label: t("menu.inspiration"),
    },
    {
      link: PAGES.COLLABORATIONS,
      label: t("home.collaborations"),
    },
    {
      link: PAGES.BRANCH,
      label: t("menu.ourLocations"),
    },
  ];

  return (
    <header className="header sticky top-0 z-[100] bg-white w-full left-0">
      <div className="flex justify-between items-center px-3 lg:px-5 bg-yellow h-[53px]">
        {/* Logo */}
        <Link to={PAGES.HOME} className="h-3/6 lg:hidden">
          <img src={FullLogo} alt="" className="h-full" />
        </Link>
        {/* Burger Menu Icon (Visible only on screens < 1024px) */}
        <div className="flex items-center gap-x-4 lg:hidden">
          <button
            onClick={() => {
              toggleSearchModal();
              if (isShowMenu) toggleMenuModal();
            }}
          >
            <VscSearch className="text-xl" />
          </button>
          <button
            onClick={() => {
              toggleMenu();
              if (isShowSearch) toggleSearchModal();
            }}
          >
            {menuOpen ? (
              <AiOutlineClose className="text-2xl" />
            ) : (
              <AiOutlineMenu className="text-2xl" />
            )}
          </button>
        </div>

        {/* Links (Hidden on small screens, visible on lg and up) */}
        <div className="hidden lg:flex gap-[8px] xl:gap-4 2xl:gap-7 w-11/12 ml-6 xl:ml-32 h-full">
          {sortedMenuPages &&
            sortedMenuPages.map?.((menuPage) => {
              const isActive =
                location.pathname ===
                `${PAGES.MENU}/${i18n.language}/${menuPage?.slug}`;
              return (
                <Link
                  className={`${
                    isActive ? "bg-white" : ""
                  } hover:bg-white flex items-center justify-center px-[8px] xl:px-3  no-underline transition-all duration-200`}
                  key={menuPage?.documentId}
                  to={`${PAGES.MENU}/${i18n.language}/${menuPage?.slug}`}
                >
                  <Typography variant="headerLink">
                    {t(menuPage?.title ?? "")}
                  </Typography>
                </Link>
              );
            })}
        </div>

        {/* Action Buttons (Visible on large screens) */}
        <div className="hidden lg:flex items-center gap-3">
          {i18n.language !== LANGS.DE && (
            <Link to={PAGES.MY_SERENI} target={"_blank"} className="w-28">
              <Button className="px-0 py-0 w-full border-0 bg-darkYellow text-black">
                {t("common.my_sereni")}
              </Button>
            </Link>
          )}
          <LanguagesList />
        </div>
      </div>
      <div className="hidden lg:flex justify-between pl-5 py-[30px] pr-40">
        <Link to={PAGES.HOME}>
          <Image
            className={"position-relative w-[100px] text-center ml-3"}
            src={FullLogo}
            alt={"logo"}
          />
        </Link>
        <div className="flex items-center">
          <Link
            to={`${PAGES.MENU}/${i18n.language}/condoleances`}
            className="no-underline"
          >
            <Button
              variant="border"
              className="mr-6 hover:bg-yellow border-yellow"
            >
              {t("common.condolences")}
            </Button>
          </Link>
          <Link to={PAGES.BRANCH} className="no-underline ">
            <Button variant="border" className="hover:bg-yellow border-yellow">
              {t("common.report_a_death")}
            </Button>
          </Link>
        </div>
      </div>
      {/* Mobile Menu (Visible only when burger menu is open on small screens) */}

      <div
        className={`lg:hidden bg-white absolute -top-[100vh] shadow-md transition-all duration-500 ease-out transform h-[calc(100vh-52px)] left-0 w-full z-[99999] opacity-0 overflow-y-auto ${
          menuOpen && "top-[52px] opacity-100"
        }`}
      >
        <div className="flex flex-col items-center p-4 gap-2">
          {sortedMenuPages &&
            sortedMenuPages.map((menuPage) => (
              <Link
                className={cn(
                  "w-full text-center px-2 py-1 hover:bg-grey rounded no-underline",
                  location.pathname ===
                    `${PAGES.MENU}/${i18n.language}/${menuPage?.slug}` &&
                    "bg-grey"
                )}
                key={menuPage?.documentId}
                to={`${PAGES.MENU}/${i18n.language}/${menuPage?.slug}`}
                onClick={() => setMenuOpen(false)}
              >
                <Typography variant="smallText">
                  {menuPage?.title ?? ""}
                </Typography>
              </Link>
            ))}
          <div className="border-t border-black my-3 w-8/12 sm:w-1/2" />
          {mobileLinks.map((link, index) => (
            <Link
              key={index}
              to={link.link}
              className="w-full text-center px-2 py-1 hover:bg-grey rounded no-underline"
            >
              <Typography variant="smallText">{link.label}</Typography>
            </Link>
          ))}
          <div className="border-t border-black my-3 w-8/12 sm:w-1/2" />
          <div className="flex flex-col w-full sm:w-9/12 items-center gap-2 ">
            <Link
              className="w-11/12 sm:w-8/12"
              to={`${PAGES.MENU}/${i18n.language}${PAGES.CONDOLEANCES}`}
            >
              <Button
                variant="border"
                className="w-full hover:bg-yellow border-yellow"
              >
                {t("common.condolences")}
              </Button>
            </Link>
            <Link className="w-11/12 sm:w-8/12" to={PAGES.BRANCH}>
              <Button
                variant="border"
                className="w-full hover:bg-yellow border-yellow"
              >
                {t("common.report_a_death")}
              </Button>
            </Link>
            {i18n.language !== LANGS.DE && (
              <Link
                to={PAGES.MY_SERENI}
                target={"_blank"}
                className="w-11/12 sm:w-8/12"
              >
                <Button className="w-full border-0 bg-darkYellow text-black">
                  {t("common.my_sereni")}
                </Button>
              </Link>
            )}
            <LanguagesList />
          </div>
        </div>
      </div>
    </header>
  );
}
