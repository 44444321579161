import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  I18NLocaleCode: any;
  JSON: any;
};

export type Article = {
  __typename?: 'Article';
  category?: Maybe<Category>;
  cover?: Maybe<UploadFile>;
  coverQuote?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ctaFirstLink?: Maybe<Scalars['String']>;
  ctaFirstText?: Maybe<Scalars['String']>;
  ctaSecondLink?: Maybe<Scalars['String']>;
  ctaSecondText?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['JSON']>;
  documentId: Scalars['ID'];
  images?: Maybe<Array<Maybe<ComponentSharedSlider>>>;
  locale?: Maybe<Scalars['String']>;
  localizations: Array<Maybe<Article>>;
  localizations_connection?: Maybe<ArticleRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoOpenGraphDescription?: Maybe<Scalars['String']>;
  seoOpenGraphImage?: Maybe<UploadFile>;
  seoOpenGraphTitle?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ArticleImagesArgs = {
  filters?: InputMaybe<ComponentSharedSliderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ArticleLocalizationsArgs = {
  filters?: InputMaybe<ArticleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ArticleLocalizations_ConnectionArgs = {
  filters?: InputMaybe<ArticleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ArticleEntityResponseCollection = {
  __typename?: 'ArticleEntityResponseCollection';
  nodes: Array<Article>;
  pageInfo: Pagination;
};

export type ArticleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ArticleFiltersInput>>>;
  category?: InputMaybe<CategoryFiltersInput>;
  coverQuote?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ctaFirstLink?: InputMaybe<StringFilterInput>;
  ctaFirstText?: InputMaybe<StringFilterInput>;
  ctaSecondLink?: InputMaybe<StringFilterInput>;
  ctaSecondText?: InputMaybe<StringFilterInput>;
  date?: InputMaybe<DateFilterInput>;
  description?: InputMaybe<JsonFilterInput>;
  documentId?: InputMaybe<IdFilterInput>;
  images?: InputMaybe<ComponentSharedSliderFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<ArticleFiltersInput>;
  not?: InputMaybe<ArticleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ArticleFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  seoDescription?: InputMaybe<StringFilterInput>;
  seoOpenGraphDescription?: InputMaybe<StringFilterInput>;
  seoOpenGraphTitle?: InputMaybe<StringFilterInput>;
  seoTitle?: InputMaybe<StringFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ArticleInput = {
  category?: InputMaybe<Scalars['ID']>;
  cover?: InputMaybe<Scalars['ID']>;
  coverQuote?: InputMaybe<Scalars['String']>;
  ctaFirstLink?: InputMaybe<Scalars['String']>;
  ctaFirstText?: InputMaybe<Scalars['String']>;
  ctaSecondLink?: InputMaybe<Scalars['String']>;
  ctaSecondText?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['JSON']>;
  images?: InputMaybe<Array<InputMaybe<ComponentSharedSliderInput>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoOpenGraphDescription?: InputMaybe<Scalars['String']>;
  seoOpenGraphImage?: InputMaybe<Scalars['ID']>;
  seoOpenGraphTitle?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ArticleRelationResponseCollection = {
  __typename?: 'ArticleRelationResponseCollection';
  nodes: Array<Article>;
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  contains?: InputMaybe<Scalars['Boolean']>;
  containsi?: InputMaybe<Scalars['Boolean']>;
  endsWith?: InputMaybe<Scalars['Boolean']>;
  eq?: InputMaybe<Scalars['Boolean']>;
  eqi?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Boolean']>;
  gte?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  lt?: InputMaybe<Scalars['Boolean']>;
  lte?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
  nei?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<BooleanFilterInput>;
  notContains?: InputMaybe<Scalars['Boolean']>;
  notContainsi?: InputMaybe<Scalars['Boolean']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  startsWith?: InputMaybe<Scalars['Boolean']>;
};

export type Category = {
  __typename?: 'Category';
  articles: Array<Maybe<Article>>;
  articles_connection?: Maybe<ArticleRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  documentId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
  localizations: Array<Maybe<Category>>;
  localizations_connection?: Maybe<CategoryRelationResponseCollection>;
  name: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CategoryArticlesArgs = {
  filters?: InputMaybe<ArticleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CategoryArticles_ConnectionArgs = {
  filters?: InputMaybe<ArticleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CategoryLocalizationsArgs = {
  filters?: InputMaybe<CategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CategoryLocalizations_ConnectionArgs = {
  filters?: InputMaybe<CategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CategoryEntityResponseCollection = {
  __typename?: 'CategoryEntityResponseCollection';
  nodes: Array<Category>;
  pageInfo: Pagination;
};

export type CategoryFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CategoryFiltersInput>>>;
  articles?: InputMaybe<ArticleFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  documentId?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<CategoryFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CategoryFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CategoryFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CategoryInput = {
  articles?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type CategoryRelationResponseCollection = {
  __typename?: 'CategoryRelationResponseCollection';
  nodes: Array<Category>;
};

export type ComponentSharedLink = {
  __typename?: 'ComponentSharedLink';
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type ComponentSharedMedia = {
  __typename?: 'ComponentSharedMedia';
  file?: Maybe<UploadFile>;
  id: Scalars['ID'];
};

export type ComponentSharedQuote = {
  __typename?: 'ComponentSharedQuote';
  body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type ComponentSharedRichText = {
  __typename?: 'ComponentSharedRichText';
  body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type ComponentSharedSeo = {
  __typename?: 'ComponentSharedSeo';
  id: Scalars['ID'];
  metaDescription: Scalars['String'];
  metaTitle: Scalars['String'];
  shareImage?: Maybe<UploadFile>;
};

export type ComponentSharedSeoInput = {
  id?: InputMaybe<Scalars['ID']>;
  metaDescription?: InputMaybe<Scalars['String']>;
  metaTitle?: InputMaybe<Scalars['String']>;
  shareImage?: InputMaybe<Scalars['ID']>;
};

export type ComponentSharedSlider = {
  __typename?: 'ComponentSharedSlider';
  files: Array<Maybe<UploadFile>>;
  files_connection?: Maybe<UploadFileRelationResponseCollection>;
  id: Scalars['ID'];
};


export type ComponentSharedSliderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ComponentSharedSliderFiles_ConnectionArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ComponentSharedSliderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedSliderFiltersInput>>>;
  not?: InputMaybe<ComponentSharedSliderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedSliderFiltersInput>>>;
};

export type ComponentSharedSliderInput = {
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  id?: InputMaybe<Scalars['ID']>;
};

export type DateFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  contains?: InputMaybe<Scalars['Date']>;
  containsi?: InputMaybe<Scalars['Date']>;
  endsWith?: InputMaybe<Scalars['Date']>;
  eq?: InputMaybe<Scalars['Date']>;
  eqi?: InputMaybe<Scalars['Date']>;
  gt?: InputMaybe<Scalars['Date']>;
  gte?: InputMaybe<Scalars['Date']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  lt?: InputMaybe<Scalars['Date']>;
  lte?: InputMaybe<Scalars['Date']>;
  ne?: InputMaybe<Scalars['Date']>;
  nei?: InputMaybe<Scalars['Date']>;
  not?: InputMaybe<DateFilterInput>;
  notContains?: InputMaybe<Scalars['Date']>;
  notContainsi?: InputMaybe<Scalars['Date']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  startsWith?: InputMaybe<Scalars['Date']>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  contains?: InputMaybe<Scalars['DateTime']>;
  containsi?: InputMaybe<Scalars['DateTime']>;
  endsWith?: InputMaybe<Scalars['DateTime']>;
  eq?: InputMaybe<Scalars['DateTime']>;
  eqi?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  ne?: InputMaybe<Scalars['DateTime']>;
  nei?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<DateTimeFilterInput>;
  notContains?: InputMaybe<Scalars['DateTime']>;
  notContainsi?: InputMaybe<Scalars['DateTime']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  startsWith?: InputMaybe<Scalars['DateTime']>;
};

export type DeleteMutationResponse = {
  __typename?: 'DeleteMutationResponse';
  documentId: Scalars['ID'];
};

export type Event = {
  __typename?: 'Event';
  address?: Maybe<Scalars['String']>;
  cover?: Maybe<UploadFile>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ctaFirstLink?: Maybe<Scalars['String']>;
  ctaFirstText?: Maybe<Scalars['String']>;
  ctaSecondLink?: Maybe<Scalars['String']>;
  ctaSecondText?: Maybe<Scalars['String']>;
  dateEnd?: Maybe<Scalars['DateTime']>;
  dateStart?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['JSON']>;
  documentId: Scalars['ID'];
  event_category?: Maybe<EventCategory>;
  images?: Maybe<Array<Maybe<ComponentSharedSlider>>>;
  locale?: Maybe<Scalars['String']>;
  localizations: Array<Maybe<Event>>;
  localizations_connection?: Maybe<EventRelationResponseCollection>;
  logo?: Maybe<UploadFile>;
  mailChimpTag?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoOpenGraphDescription?: Maybe<Scalars['String']>;
  seoOpenGraphImage?: Maybe<UploadFile>;
  seoOpenGraphTitle?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type EventImagesArgs = {
  filters?: InputMaybe<ComponentSharedSliderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type EventLocalizationsArgs = {
  filters?: InputMaybe<EventFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type EventLocalizations_ConnectionArgs = {
  filters?: InputMaybe<EventFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EventCategory = {
  __typename?: 'EventCategory';
  createdAt?: Maybe<Scalars['DateTime']>;
  documentId: Scalars['ID'];
  events: Array<Maybe<Event>>;
  events_connection?: Maybe<EventRelationResponseCollection>;
  locale?: Maybe<Scalars['String']>;
  localizations: Array<Maybe<EventCategory>>;
  localizations_connection?: Maybe<EventCategoryRelationResponseCollection>;
  name: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type EventCategoryEventsArgs = {
  filters?: InputMaybe<EventFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type EventCategoryEvents_ConnectionArgs = {
  filters?: InputMaybe<EventFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type EventCategoryLocalizationsArgs = {
  filters?: InputMaybe<EventCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type EventCategoryLocalizations_ConnectionArgs = {
  filters?: InputMaybe<EventCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type EventCategoryEntityResponseCollection = {
  __typename?: 'EventCategoryEntityResponseCollection';
  nodes: Array<EventCategory>;
  pageInfo: Pagination;
};

export type EventCategoryFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<EventCategoryFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  documentId?: InputMaybe<IdFilterInput>;
  events?: InputMaybe<EventFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<EventCategoryFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<EventCategoryFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<EventCategoryFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type EventCategoryInput = {
  events?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type EventCategoryRelationResponseCollection = {
  __typename?: 'EventCategoryRelationResponseCollection';
  nodes: Array<EventCategory>;
};

export type EventEntityResponseCollection = {
  __typename?: 'EventEntityResponseCollection';
  nodes: Array<Event>;
  pageInfo: Pagination;
};

export type EventFiltersInput = {
  address?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<EventFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ctaFirstLink?: InputMaybe<StringFilterInput>;
  ctaFirstText?: InputMaybe<StringFilterInput>;
  ctaSecondLink?: InputMaybe<StringFilterInput>;
  ctaSecondText?: InputMaybe<StringFilterInput>;
  dateEnd?: InputMaybe<DateTimeFilterInput>;
  dateStart?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<JsonFilterInput>;
  documentId?: InputMaybe<IdFilterInput>;
  event_category?: InputMaybe<EventCategoryFiltersInput>;
  images?: InputMaybe<ComponentSharedSliderFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<EventFiltersInput>;
  mailChimpTag?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<EventFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<EventFiltersInput>>>;
  price?: InputMaybe<FloatFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  seoDescription?: InputMaybe<StringFilterInput>;
  seoOpenGraphDescription?: InputMaybe<StringFilterInput>;
  seoOpenGraphTitle?: InputMaybe<StringFilterInput>;
  seoTitle?: InputMaybe<StringFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type EventInput = {
  address?: InputMaybe<Scalars['String']>;
  cover?: InputMaybe<Scalars['ID']>;
  ctaFirstLink?: InputMaybe<Scalars['String']>;
  ctaFirstText?: InputMaybe<Scalars['String']>;
  ctaSecondLink?: InputMaybe<Scalars['String']>;
  ctaSecondText?: InputMaybe<Scalars['String']>;
  dateEnd?: InputMaybe<Scalars['DateTime']>;
  dateStart?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['JSON']>;
  event_category?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<Array<InputMaybe<ComponentSharedSliderInput>>>;
  logo?: InputMaybe<Scalars['ID']>;
  mailChimpTag?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoOpenGraphDescription?: InputMaybe<Scalars['String']>;
  seoOpenGraphImage?: InputMaybe<Scalars['ID']>;
  seoOpenGraphTitle?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type EventRelationResponseCollection = {
  __typename?: 'EventRelationResponseCollection';
  nodes: Array<Event>;
};

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  contains?: InputMaybe<Scalars['Float']>;
  containsi?: InputMaybe<Scalars['Float']>;
  endsWith?: InputMaybe<Scalars['Float']>;
  eq?: InputMaybe<Scalars['Float']>;
  eqi?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
  nei?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<FloatFilterInput>;
  notContains?: InputMaybe<Scalars['Float']>;
  notContainsi?: InputMaybe<Scalars['Float']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  startsWith?: InputMaybe<Scalars['Float']>;
};

export type GenericMorph = Article | Category | ComponentSharedLink | ComponentSharedMedia | ComponentSharedQuote | ComponentSharedRichText | ComponentSharedSeo | ComponentSharedSlider | Event | EventCategory | Global | HeroCarousel | HeroLink | I18NLocale | Inspiration | InspirationCategory | Job | Location | Magazine | MenuCategory | MenuPage | Partner | PartnerCategory | ReviewWorkflowsWorkflow | ReviewWorkflowsWorkflowStage | UploadFile | UsersPermissionsPermission | UsersPermissionsRole | UsersPermissionsUser;

export type Global = {
  __typename?: 'Global';
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultSeo?: Maybe<ComponentSharedSeo>;
  documentId: Scalars['ID'];
  favicon?: Maybe<UploadFile>;
  locale?: Maybe<Scalars['String']>;
  localizations: Array<Maybe<Global>>;
  localizations_connection?: Maybe<GlobalRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  siteDescription: Scalars['String'];
  siteName: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GlobalInput = {
  defaultSeo?: InputMaybe<ComponentSharedSeoInput>;
  favicon?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  siteDescription?: InputMaybe<Scalars['String']>;
  siteName?: InputMaybe<Scalars['String']>;
};

export type GlobalRelationResponseCollection = {
  __typename?: 'GlobalRelationResponseCollection';
  nodes: Array<Global>;
};

export type HeroCarousel = {
  __typename?: 'HeroCarousel';
  createdAt?: Maybe<Scalars['DateTime']>;
  documentId: Scalars['ID'];
  images?: Maybe<Array<Maybe<ComponentSharedSlider>>>;
  locale?: Maybe<Scalars['String']>;
  localizations: Array<Maybe<HeroCarousel>>;
  localizations_connection?: Maybe<HeroCarouselRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type HeroCarouselImagesArgs = {
  filters?: InputMaybe<ComponentSharedSliderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type HeroCarouselInput = {
  images?: InputMaybe<Array<InputMaybe<ComponentSharedSliderInput>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
};

export type HeroCarouselRelationResponseCollection = {
  __typename?: 'HeroCarouselRelationResponseCollection';
  nodes: Array<HeroCarousel>;
};

export type HeroLink = {
  __typename?: 'HeroLink';
  createdAt?: Maybe<Scalars['DateTime']>;
  documentId: Scalars['ID'];
  link: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
  localizations: Array<Maybe<HeroLink>>;
  localizations_connection?: Maybe<HeroLinkRelationResponseCollection>;
  priority?: Maybe<Scalars['Int']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type HeroLinkLocalizationsArgs = {
  filters?: InputMaybe<HeroLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type HeroLinkLocalizations_ConnectionArgs = {
  filters?: InputMaybe<HeroLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type HeroLinkEntityResponseCollection = {
  __typename?: 'HeroLinkEntityResponseCollection';
  nodes: Array<HeroLink>;
  pageInfo: Pagination;
};

export type HeroLinkFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<HeroLinkFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  documentId?: InputMaybe<IdFilterInput>;
  link?: InputMaybe<StringFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<HeroLinkFiltersInput>;
  not?: InputMaybe<HeroLinkFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<HeroLinkFiltersInput>>>;
  priority?: InputMaybe<IntFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  text?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type HeroLinkInput = {
  link?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  text?: InputMaybe<Scalars['String']>;
};

export type HeroLinkRelationResponseCollection = {
  __typename?: 'HeroLinkRelationResponseCollection';
  nodes: Array<HeroLink>;
};

export type I18NLocale = {
  __typename?: 'I18NLocale';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  documentId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  nodes: Array<I18NLocale>;
  pageInfo: Pagination;
};

export type I18NLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  documentId?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contains?: InputMaybe<Scalars['ID']>;
  containsi?: InputMaybe<Scalars['ID']>;
  endsWith?: InputMaybe<Scalars['ID']>;
  eq?: InputMaybe<Scalars['ID']>;
  eqi?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  nei?: InputMaybe<Scalars['ID']>;
  not?: InputMaybe<IdFilterInput>;
  notContains?: InputMaybe<Scalars['ID']>;
  notContainsi?: InputMaybe<Scalars['ID']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  startsWith?: InputMaybe<Scalars['ID']>;
};

export type Inspiration = {
  __typename?: 'Inspiration';
  address?: Maybe<Scalars['String']>;
  cover?: Maybe<UploadFile>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ctaFirstLink?: Maybe<Scalars['String']>;
  ctaFirstText?: Maybe<Scalars['String']>;
  ctaSecondLink?: Maybe<Scalars['String']>;
  ctaSecondText?: Maybe<Scalars['String']>;
  dateEnd?: Maybe<Scalars['DateTime']>;
  dateStart?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['JSON']>;
  documentId: Scalars['ID'];
  event_category?: Maybe<EventCategory>;
  images?: Maybe<Array<Maybe<ComponentSharedSlider>>>;
  inspiration_category?: Maybe<InspirationCategory>;
  locale?: Maybe<Scalars['String']>;
  localizations: Array<Maybe<Inspiration>>;
  localizations_connection?: Maybe<InspirationRelationResponseCollection>;
  logo?: Maybe<UploadFile>;
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoOpenGraphDescription?: Maybe<Scalars['String']>;
  seoOpenGraphImage?: Maybe<UploadFile>;
  seoOpenGraphTitle?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type InspirationImagesArgs = {
  filters?: InputMaybe<ComponentSharedSliderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type InspirationLocalizationsArgs = {
  filters?: InputMaybe<InspirationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type InspirationLocalizations_ConnectionArgs = {
  filters?: InputMaybe<InspirationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type InspirationCategory = {
  __typename?: 'InspirationCategory';
  createdAt?: Maybe<Scalars['DateTime']>;
  documentId: Scalars['ID'];
  inspirations: Array<Maybe<Inspiration>>;
  inspirations_connection?: Maybe<InspirationRelationResponseCollection>;
  locale?: Maybe<Scalars['String']>;
  localizations: Array<Maybe<InspirationCategory>>;
  localizations_connection?: Maybe<InspirationCategoryRelationResponseCollection>;
  name: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type InspirationCategoryInspirationsArgs = {
  filters?: InputMaybe<InspirationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type InspirationCategoryInspirations_ConnectionArgs = {
  filters?: InputMaybe<InspirationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type InspirationCategoryLocalizationsArgs = {
  filters?: InputMaybe<InspirationCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type InspirationCategoryLocalizations_ConnectionArgs = {
  filters?: InputMaybe<InspirationCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type InspirationCategoryEntityResponseCollection = {
  __typename?: 'InspirationCategoryEntityResponseCollection';
  nodes: Array<InspirationCategory>;
  pageInfo: Pagination;
};

export type InspirationCategoryFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<InspirationCategoryFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  documentId?: InputMaybe<IdFilterInput>;
  inspirations?: InputMaybe<InspirationFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<InspirationCategoryFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<InspirationCategoryFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<InspirationCategoryFiltersInput>>>;
  priority?: InputMaybe<IntFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type InspirationCategoryInput = {
  inspirations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type InspirationCategoryRelationResponseCollection = {
  __typename?: 'InspirationCategoryRelationResponseCollection';
  nodes: Array<InspirationCategory>;
};

export type InspirationEntityResponseCollection = {
  __typename?: 'InspirationEntityResponseCollection';
  nodes: Array<Inspiration>;
  pageInfo: Pagination;
};

export type InspirationFiltersInput = {
  address?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<InspirationFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ctaFirstLink?: InputMaybe<StringFilterInput>;
  ctaFirstText?: InputMaybe<StringFilterInput>;
  ctaSecondLink?: InputMaybe<StringFilterInput>;
  ctaSecondText?: InputMaybe<StringFilterInput>;
  dateEnd?: InputMaybe<DateTimeFilterInput>;
  dateStart?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<JsonFilterInput>;
  documentId?: InputMaybe<IdFilterInput>;
  event_category?: InputMaybe<EventCategoryFiltersInput>;
  images?: InputMaybe<ComponentSharedSliderFiltersInput>;
  inspiration_category?: InputMaybe<InspirationCategoryFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<InspirationFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<InspirationFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<InspirationFiltersInput>>>;
  price?: InputMaybe<FloatFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  seoDescription?: InputMaybe<StringFilterInput>;
  seoOpenGraphDescription?: InputMaybe<StringFilterInput>;
  seoOpenGraphTitle?: InputMaybe<StringFilterInput>;
  seoTitle?: InputMaybe<StringFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type InspirationInput = {
  address?: InputMaybe<Scalars['String']>;
  cover?: InputMaybe<Scalars['ID']>;
  ctaFirstLink?: InputMaybe<Scalars['String']>;
  ctaFirstText?: InputMaybe<Scalars['String']>;
  ctaSecondLink?: InputMaybe<Scalars['String']>;
  ctaSecondText?: InputMaybe<Scalars['String']>;
  dateEnd?: InputMaybe<Scalars['DateTime']>;
  dateStart?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['JSON']>;
  event_category?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<Array<InputMaybe<ComponentSharedSliderInput>>>;
  inspiration_category?: InputMaybe<Scalars['ID']>;
  logo?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoOpenGraphDescription?: InputMaybe<Scalars['String']>;
  seoOpenGraphImage?: InputMaybe<Scalars['ID']>;
  seoOpenGraphTitle?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type InspirationRelationResponseCollection = {
  __typename?: 'InspirationRelationResponseCollection';
  nodes: Array<Inspiration>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  contains?: InputMaybe<Scalars['Int']>;
  containsi?: InputMaybe<Scalars['Int']>;
  endsWith?: InputMaybe<Scalars['Int']>;
  eq?: InputMaybe<Scalars['Int']>;
  eqi?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
  nei?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<IntFilterInput>;
  notContains?: InputMaybe<Scalars['Int']>;
  notContainsi?: InputMaybe<Scalars['Int']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  startsWith?: InputMaybe<Scalars['Int']>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  contains?: InputMaybe<Scalars['JSON']>;
  containsi?: InputMaybe<Scalars['JSON']>;
  endsWith?: InputMaybe<Scalars['JSON']>;
  eq?: InputMaybe<Scalars['JSON']>;
  eqi?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  ne?: InputMaybe<Scalars['JSON']>;
  nei?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<JsonFilterInput>;
  notContains?: InputMaybe<Scalars['JSON']>;
  notContainsi?: InputMaybe<Scalars['JSON']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  startsWith?: InputMaybe<Scalars['JSON']>;
};

export type Job = {
  __typename?: 'Job';
  createdAt?: Maybe<Scalars['DateTime']>;
  ctaFirstLink?: Maybe<Scalars['String']>;
  ctaFirstText?: Maybe<Scalars['String']>;
  ctaSecondLink?: Maybe<Scalars['String']>;
  ctaSecondText?: Maybe<Scalars['String']>;
  desciption?: Maybe<Scalars['JSON']>;
  documentId: Scalars['ID'];
  images?: Maybe<Array<Maybe<ComponentSharedSlider>>>;
  locale?: Maybe<Scalars['String']>;
  localizations: Array<Maybe<Job>>;
  localizations_connection?: Maybe<JobRelationResponseCollection>;
  name: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoOpenGraphDescription?: Maybe<Scalars['String']>;
  seoOpenGraphImage?: Maybe<UploadFile>;
  seoOpenGraphTitle?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type JobImagesArgs = {
  filters?: InputMaybe<ComponentSharedSliderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type JobLocalizationsArgs = {
  filters?: InputMaybe<JobFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type JobLocalizations_ConnectionArgs = {
  filters?: InputMaybe<JobFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type JobEntityResponseCollection = {
  __typename?: 'JobEntityResponseCollection';
  nodes: Array<Job>;
  pageInfo: Pagination;
};

export type JobFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<JobFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ctaFirstLink?: InputMaybe<StringFilterInput>;
  ctaFirstText?: InputMaybe<StringFilterInput>;
  ctaSecondLink?: InputMaybe<StringFilterInput>;
  ctaSecondText?: InputMaybe<StringFilterInput>;
  desciption?: InputMaybe<JsonFilterInput>;
  documentId?: InputMaybe<IdFilterInput>;
  images?: InputMaybe<ComponentSharedSliderFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<JobFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<JobFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<JobFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  seoDescription?: InputMaybe<StringFilterInput>;
  seoOpenGraphDescription?: InputMaybe<StringFilterInput>;
  seoOpenGraphTitle?: InputMaybe<StringFilterInput>;
  seoTitle?: InputMaybe<StringFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type JobInput = {
  ctaFirstLink?: InputMaybe<Scalars['String']>;
  ctaFirstText?: InputMaybe<Scalars['String']>;
  ctaSecondLink?: InputMaybe<Scalars['String']>;
  ctaSecondText?: InputMaybe<Scalars['String']>;
  desciption?: InputMaybe<Scalars['JSON']>;
  images?: InputMaybe<Array<InputMaybe<ComponentSharedSliderInput>>>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoOpenGraphDescription?: InputMaybe<Scalars['String']>;
  seoOpenGraphImage?: InputMaybe<Scalars['ID']>;
  seoOpenGraphTitle?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type JobRelationResponseCollection = {
  __typename?: 'JobRelationResponseCollection';
  nodes: Array<Job>;
};

export type Location = {
  __typename?: 'Location';
  address: Scalars['String'];
  cover?: Maybe<UploadFile>;
  createdAt?: Maybe<Scalars['DateTime']>;
  documentId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  externalLink?: Maybe<Scalars['String']>;
  externalLinkText?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  localizations: Array<Maybe<Location>>;
  localizations_connection?: Maybe<LocationRelationResponseCollection>;
  location?: Maybe<Scalars['JSON']>;
  logo?: Maybe<UploadFile>;
  phone?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoOpenGraphDescription?: Maybe<Scalars['String']>;
  seoOpenGraphImage?: Maybe<UploadFile>;
  seoOpenGraphTitle?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zipcode?: Maybe<Scalars['String']>;
};


export type LocationLocalizationsArgs = {
  filters?: InputMaybe<LocationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type LocationLocalizations_ConnectionArgs = {
  filters?: InputMaybe<LocationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type LocationEntityResponseCollection = {
  __typename?: 'LocationEntityResponseCollection';
  nodes: Array<Location>;
  pageInfo: Pagination;
};

export type LocationFiltersInput = {
  address?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<LocationFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  documentId?: InputMaybe<IdFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  externalLink?: InputMaybe<StringFilterInput>;
  externalLinkText?: InputMaybe<StringFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<LocationFiltersInput>;
  location?: InputMaybe<JsonFilterInput>;
  not?: InputMaybe<LocationFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<LocationFiltersInput>>>;
  phone?: InputMaybe<StringFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  seoDescription?: InputMaybe<StringFilterInput>;
  seoOpenGraphDescription?: InputMaybe<StringFilterInput>;
  seoOpenGraphTitle?: InputMaybe<StringFilterInput>;
  seoTitle?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  zipcode?: InputMaybe<StringFilterInput>;
};

export type LocationInput = {
  address?: InputMaybe<Scalars['String']>;
  cover?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  externalLink?: InputMaybe<Scalars['String']>;
  externalLinkText?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['JSON']>;
  logo?: InputMaybe<Scalars['ID']>;
  phone?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoOpenGraphDescription?: InputMaybe<Scalars['String']>;
  seoOpenGraphImage?: InputMaybe<Scalars['ID']>;
  seoOpenGraphTitle?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type LocationRelationResponseCollection = {
  __typename?: 'LocationRelationResponseCollection';
  nodes: Array<Location>;
};

export type Magazine = {
  __typename?: 'Magazine';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['JSON']>;
  documentId: Scalars['ID'];
  externalLink?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<ComponentSharedSlider>>>;
  locale?: Maybe<Scalars['String']>;
  localizations: Array<Maybe<Magazine>>;
  localizations_connection?: Maybe<MagazineRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoOpenGraphDescription?: Maybe<Scalars['String']>;
  seoOpenGraphImage?: Maybe<UploadFile>;
  seoOpenGraphTitle?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type MagazineImagesArgs = {
  filters?: InputMaybe<ComponentSharedSliderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MagazineInput = {
  description?: InputMaybe<Scalars['JSON']>;
  externalLink?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<InputMaybe<ComponentSharedSliderInput>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoOpenGraphDescription?: InputMaybe<Scalars['String']>;
  seoOpenGraphImage?: InputMaybe<Scalars['ID']>;
  seoOpenGraphTitle?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type MagazineRelationResponseCollection = {
  __typename?: 'MagazineRelationResponseCollection';
  nodes: Array<Magazine>;
};

export type MenuCategory = {
  __typename?: 'MenuCategory';
  createdAt?: Maybe<Scalars['DateTime']>;
  documentId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
  localizations: Array<Maybe<MenuCategory>>;
  localizations_connection?: Maybe<MenuCategoryRelationResponseCollection>;
  menu_pages: Array<Maybe<MenuPage>>;
  menu_pages_connection?: Maybe<MenuPageRelationResponseCollection>;
  name: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type MenuCategoryLocalizationsArgs = {
  filters?: InputMaybe<MenuCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MenuCategoryLocalizations_ConnectionArgs = {
  filters?: InputMaybe<MenuCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MenuCategoryMenu_PagesArgs = {
  filters?: InputMaybe<MenuPageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MenuCategoryMenu_Pages_ConnectionArgs = {
  filters?: InputMaybe<MenuPageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MenuCategoryEntityResponseCollection = {
  __typename?: 'MenuCategoryEntityResponseCollection';
  nodes: Array<MenuCategory>;
  pageInfo: Pagination;
};

export type MenuCategoryFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<MenuCategoryFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  documentId?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<MenuCategoryFiltersInput>;
  menu_pages?: InputMaybe<MenuPageFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<MenuCategoryFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<MenuCategoryFiltersInput>>>;
  priority?: InputMaybe<IntFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type MenuCategoryInput = {
  menu_pages?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type MenuCategoryRelationResponseCollection = {
  __typename?: 'MenuCategoryRelationResponseCollection';
  nodes: Array<MenuCategory>;
};

export type MenuPage = {
  __typename?: 'MenuPage';
  cover?: Maybe<UploadFile>;
  coverQuote?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ctaFirstLink?: Maybe<Scalars['String']>;
  ctaFirstText?: Maybe<Scalars['String']>;
  ctaSecondLink?: Maybe<Scalars['String']>;
  ctaSecondText?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['JSON']>;
  documentId: Scalars['ID'];
  images?: Maybe<Array<Maybe<ComponentSharedSlider>>>;
  locale?: Maybe<Scalars['String']>;
  localizations: Array<Maybe<MenuPage>>;
  localizations_connection?: Maybe<MenuPageRelationResponseCollection>;
  menu_category?: Maybe<MenuCategory>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoOpenGraphDescription?: Maybe<Scalars['String']>;
  seoOpenGraphImage?: Maybe<UploadFile>;
  seoTitle?: Maybe<Scalars['String']>;
  showInBurgerMenu?: Maybe<Scalars['Boolean']>;
  showInBurgerMenuPriority?: Maybe<Scalars['Int']>;
  showInFooter?: Maybe<Scalars['Boolean']>;
  showInFooterPriority?: Maybe<Scalars['Int']>;
  showInHeader?: Maybe<Scalars['Boolean']>;
  showInHeaderPriority?: Maybe<Scalars['Int']>;
  slug: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type MenuPageImagesArgs = {
  filters?: InputMaybe<ComponentSharedSliderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MenuPageLocalizationsArgs = {
  filters?: InputMaybe<MenuPageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MenuPageLocalizations_ConnectionArgs = {
  filters?: InputMaybe<MenuPageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MenuPageEntityResponseCollection = {
  __typename?: 'MenuPageEntityResponseCollection';
  nodes: Array<MenuPage>;
  pageInfo: Pagination;
};

export type MenuPageFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<MenuPageFiltersInput>>>;
  coverQuote?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ctaFirstLink?: InputMaybe<StringFilterInput>;
  ctaFirstText?: InputMaybe<StringFilterInput>;
  ctaSecondLink?: InputMaybe<StringFilterInput>;
  ctaSecondText?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<JsonFilterInput>;
  documentId?: InputMaybe<IdFilterInput>;
  images?: InputMaybe<ComponentSharedSliderFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<MenuPageFiltersInput>;
  menu_category?: InputMaybe<MenuCategoryFiltersInput>;
  not?: InputMaybe<MenuPageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<MenuPageFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  seoDescription?: InputMaybe<StringFilterInput>;
  seoOpenGraphDescription?: InputMaybe<StringFilterInput>;
  seoTitle?: InputMaybe<StringFilterInput>;
  showInBurgerMenu?: InputMaybe<BooleanFilterInput>;
  showInBurgerMenuPriority?: InputMaybe<IntFilterInput>;
  showInFooter?: InputMaybe<BooleanFilterInput>;
  showInFooterPriority?: InputMaybe<IntFilterInput>;
  showInHeader?: InputMaybe<BooleanFilterInput>;
  showInHeaderPriority?: InputMaybe<IntFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type MenuPageInput = {
  cover?: InputMaybe<Scalars['ID']>;
  coverQuote?: InputMaybe<Scalars['String']>;
  ctaFirstLink?: InputMaybe<Scalars['String']>;
  ctaFirstText?: InputMaybe<Scalars['String']>;
  ctaSecondLink?: InputMaybe<Scalars['String']>;
  ctaSecondText?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['JSON']>;
  images?: InputMaybe<Array<InputMaybe<ComponentSharedSliderInput>>>;
  menu_category?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoOpenGraphDescription?: InputMaybe<Scalars['String']>;
  seoOpenGraphImage?: InputMaybe<Scalars['ID']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  showInBurgerMenu?: InputMaybe<Scalars['Boolean']>;
  showInBurgerMenuPriority?: InputMaybe<Scalars['Int']>;
  showInFooter?: InputMaybe<Scalars['Boolean']>;
  showInFooterPriority?: InputMaybe<Scalars['Int']>;
  showInHeader?: InputMaybe<Scalars['Boolean']>;
  showInHeaderPriority?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type MenuPageRelationResponseCollection = {
  __typename?: 'MenuPageRelationResponseCollection';
  nodes: Array<MenuPage>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  createArticle?: Maybe<Article>;
  createCategory?: Maybe<Category>;
  createEvent?: Maybe<Event>;
  createEventCategory?: Maybe<EventCategory>;
  createHeroLink?: Maybe<HeroLink>;
  createInspiration?: Maybe<Inspiration>;
  createInspirationCategory?: Maybe<InspirationCategory>;
  createJob?: Maybe<Job>;
  createLocation?: Maybe<Location>;
  createMenuCategory?: Maybe<MenuCategory>;
  createMenuPage?: Maybe<MenuPage>;
  createPartner?: Maybe<Partner>;
  createPartnerCategory?: Maybe<PartnerCategory>;
  createReviewWorkflowsWorkflow?: Maybe<ReviewWorkflowsWorkflow>;
  createReviewWorkflowsWorkflowStage?: Maybe<ReviewWorkflowsWorkflowStage>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteArticle?: Maybe<DeleteMutationResponse>;
  deleteCategory?: Maybe<DeleteMutationResponse>;
  deleteEvent?: Maybe<DeleteMutationResponse>;
  deleteEventCategory?: Maybe<DeleteMutationResponse>;
  deleteGlobal?: Maybe<DeleteMutationResponse>;
  deleteHeroCarousel?: Maybe<DeleteMutationResponse>;
  deleteHeroLink?: Maybe<DeleteMutationResponse>;
  deleteInspiration?: Maybe<DeleteMutationResponse>;
  deleteInspirationCategory?: Maybe<DeleteMutationResponse>;
  deleteJob?: Maybe<DeleteMutationResponse>;
  deleteLocation?: Maybe<DeleteMutationResponse>;
  deleteMagazine?: Maybe<DeleteMutationResponse>;
  deleteMenuCategory?: Maybe<DeleteMutationResponse>;
  deleteMenuPage?: Maybe<DeleteMutationResponse>;
  deletePartner?: Maybe<DeleteMutationResponse>;
  deletePartnerCategory?: Maybe<DeleteMutationResponse>;
  deleteReviewWorkflowsWorkflow?: Maybe<DeleteMutationResponse>;
  deleteReviewWorkflowsWorkflowStage?: Maybe<DeleteMutationResponse>;
  deleteUploadFile?: Maybe<UploadFile>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  updateArticle?: Maybe<Article>;
  updateCategory?: Maybe<Category>;
  updateEvent?: Maybe<Event>;
  updateEventCategory?: Maybe<EventCategory>;
  updateGlobal?: Maybe<Global>;
  updateHeroCarousel?: Maybe<HeroCarousel>;
  updateHeroLink?: Maybe<HeroLink>;
  updateInspiration?: Maybe<Inspiration>;
  updateInspirationCategory?: Maybe<InspirationCategory>;
  updateJob?: Maybe<Job>;
  updateLocation?: Maybe<Location>;
  updateMagazine?: Maybe<Magazine>;
  updateMenuCategory?: Maybe<MenuCategory>;
  updateMenuPage?: Maybe<MenuPage>;
  updatePartner?: Maybe<Partner>;
  updatePartnerCategory?: Maybe<PartnerCategory>;
  updateReviewWorkflowsWorkflow?: Maybe<ReviewWorkflowsWorkflow>;
  updateReviewWorkflowsWorkflowStage?: Maybe<ReviewWorkflowsWorkflowStage>;
  updateUploadFile: UploadFile;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationCreateArticleArgs = {
  data: ArticleInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationCreateCategoryArgs = {
  data: CategoryInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationCreateEventArgs = {
  data: EventInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationCreateEventCategoryArgs = {
  data: EventCategoryInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationCreateHeroLinkArgs = {
  data: HeroLinkInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationCreateInspirationArgs = {
  data: InspirationInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationCreateInspirationCategoryArgs = {
  data: InspirationCategoryInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationCreateJobArgs = {
  data: JobInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationCreateLocationArgs = {
  data: LocationInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationCreateMenuCategoryArgs = {
  data: MenuCategoryInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationCreateMenuPageArgs = {
  data: MenuPageInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationCreatePartnerArgs = {
  data: PartnerInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationCreatePartnerCategoryArgs = {
  data: PartnerCategoryInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationCreateReviewWorkflowsWorkflowArgs = {
  data: ReviewWorkflowsWorkflowInput;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationCreateReviewWorkflowsWorkflowStageArgs = {
  data: ReviewWorkflowsWorkflowStageInput;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};


export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};


export type MutationDeleteArticleArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteCategoryArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteEventArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteEventCategoryArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteGlobalArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteHeroCarouselArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteHeroLinkArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteInspirationArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteInspirationCategoryArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteJobArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteLocationArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteMagazineArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteMenuCategoryArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteMenuPageArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeletePartnerArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeletePartnerCategoryArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteReviewWorkflowsWorkflowArgs = {
  documentId: Scalars['ID'];
};


export type MutationDeleteReviewWorkflowsWorkflowStageArgs = {
  documentId: Scalars['ID'];
};


export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID'];
};


export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};


export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};


export type MutationResetPasswordArgs = {
  code: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationUpdateArticleArgs = {
  data: ArticleInput;
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationUpdateCategoryArgs = {
  data: CategoryInput;
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationUpdateEventArgs = {
  data: EventInput;
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationUpdateEventCategoryArgs = {
  data: EventCategoryInput;
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationUpdateGlobalArgs = {
  data: GlobalInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationUpdateHeroCarouselArgs = {
  data: HeroCarouselInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationUpdateHeroLinkArgs = {
  data: HeroLinkInput;
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationUpdateInspirationArgs = {
  data: InspirationInput;
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationUpdateInspirationCategoryArgs = {
  data: InspirationCategoryInput;
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationUpdateJobArgs = {
  data: JobInput;
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationUpdateLocationArgs = {
  data: LocationInput;
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationUpdateMagazineArgs = {
  data: MagazineInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationUpdateMenuCategoryArgs = {
  data: MenuCategoryInput;
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationUpdateMenuPageArgs = {
  data: MenuPageInput;
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationUpdatePartnerArgs = {
  data: PartnerInput;
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationUpdatePartnerCategoryArgs = {
  data: PartnerCategoryInput;
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type MutationUpdateReviewWorkflowsWorkflowArgs = {
  data: ReviewWorkflowsWorkflowInput;
  documentId: Scalars['ID'];
  status?: InputMaybe<PublicationStatus>;
};


export type MutationUpdateReviewWorkflowsWorkflowStageArgs = {
  data: ReviewWorkflowsWorkflowStageInput;
  documentId: Scalars['ID'];
  status?: InputMaybe<PublicationStatus>;
};


export type MutationUpdateUploadFileArgs = {
  id: Scalars['ID'];
  info?: InputMaybe<FileInfoInput>;
};


export type MutationUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars['ID'];
};


export type MutationUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars['ID'];
};

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type PaginationArg = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['Int']>;
};

export type Partner = {
  __typename?: 'Partner';
  cover: UploadFile;
  coverQuote?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ctaFirstLink?: Maybe<Scalars['String']>;
  ctaFirstText?: Maybe<Scalars['String']>;
  ctaSecondLink?: Maybe<Scalars['String']>;
  ctaSecondText?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['JSON']>;
  documentId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  externalLink?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<ComponentSharedSlider>>>;
  locale?: Maybe<Scalars['String']>;
  localizations: Array<Maybe<Partner>>;
  localizations_connection?: Maybe<PartnerRelationResponseCollection>;
  name: Scalars['String'];
  partner_category?: Maybe<PartnerCategory>;
  phone?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoOpenGraphDescription?: Maybe<Scalars['String']>;
  seoOpenGraphImage?: Maybe<UploadFile>;
  seoOpenGraphTitle?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type PartnerImagesArgs = {
  filters?: InputMaybe<ComponentSharedSliderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type PartnerLocalizationsArgs = {
  filters?: InputMaybe<PartnerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type PartnerLocalizations_ConnectionArgs = {
  filters?: InputMaybe<PartnerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PartnerCategory = {
  __typename?: 'PartnerCategory';
  createdAt?: Maybe<Scalars['DateTime']>;
  documentId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
  localizations: Array<Maybe<PartnerCategory>>;
  localizations_connection?: Maybe<PartnerCategoryRelationResponseCollection>;
  name: Scalars['String'];
  partners: Array<Maybe<Partner>>;
  partners_connection?: Maybe<PartnerRelationResponseCollection>;
  priority?: Maybe<Scalars['Int']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type PartnerCategoryLocalizationsArgs = {
  filters?: InputMaybe<PartnerCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type PartnerCategoryLocalizations_ConnectionArgs = {
  filters?: InputMaybe<PartnerCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type PartnerCategoryPartnersArgs = {
  filters?: InputMaybe<PartnerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type PartnerCategoryPartners_ConnectionArgs = {
  filters?: InputMaybe<PartnerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PartnerCategoryEntityResponseCollection = {
  __typename?: 'PartnerCategoryEntityResponseCollection';
  nodes: Array<PartnerCategory>;
  pageInfo: Pagination;
};

export type PartnerCategoryFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PartnerCategoryFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  documentId?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<PartnerCategoryFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<PartnerCategoryFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PartnerCategoryFiltersInput>>>;
  partners?: InputMaybe<PartnerFiltersInput>;
  priority?: InputMaybe<IntFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PartnerCategoryInput = {
  name?: InputMaybe<Scalars['String']>;
  partners?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  priority?: InputMaybe<Scalars['Int']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type PartnerCategoryRelationResponseCollection = {
  __typename?: 'PartnerCategoryRelationResponseCollection';
  nodes: Array<PartnerCategory>;
};

export type PartnerEntityResponseCollection = {
  __typename?: 'PartnerEntityResponseCollection';
  nodes: Array<Partner>;
  pageInfo: Pagination;
};

export type PartnerFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PartnerFiltersInput>>>;
  coverQuote?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ctaFirstLink?: InputMaybe<StringFilterInput>;
  ctaFirstText?: InputMaybe<StringFilterInput>;
  ctaSecondLink?: InputMaybe<StringFilterInput>;
  ctaSecondText?: InputMaybe<StringFilterInput>;
  date?: InputMaybe<DateFilterInput>;
  description?: InputMaybe<JsonFilterInput>;
  documentId?: InputMaybe<IdFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  externalLink?: InputMaybe<StringFilterInput>;
  images?: InputMaybe<ComponentSharedSliderFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<PartnerFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<PartnerFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PartnerFiltersInput>>>;
  partner_category?: InputMaybe<PartnerCategoryFiltersInput>;
  phone?: InputMaybe<StringFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  seoDescription?: InputMaybe<StringFilterInput>;
  seoOpenGraphDescription?: InputMaybe<StringFilterInput>;
  seoOpenGraphTitle?: InputMaybe<StringFilterInput>;
  seoTitle?: InputMaybe<StringFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PartnerInput = {
  cover?: InputMaybe<Scalars['ID']>;
  coverQuote?: InputMaybe<Scalars['String']>;
  ctaFirstLink?: InputMaybe<Scalars['String']>;
  ctaFirstText?: InputMaybe<Scalars['String']>;
  ctaSecondLink?: InputMaybe<Scalars['String']>;
  ctaSecondText?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['JSON']>;
  email?: InputMaybe<Scalars['String']>;
  externalLink?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<InputMaybe<ComponentSharedSliderInput>>>;
  name?: InputMaybe<Scalars['String']>;
  partner_category?: InputMaybe<Scalars['ID']>;
  phone?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoOpenGraphDescription?: InputMaybe<Scalars['String']>;
  seoOpenGraphImage?: InputMaybe<Scalars['ID']>;
  seoOpenGraphTitle?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type PartnerRelationResponseCollection = {
  __typename?: 'PartnerRelationResponseCollection';
  nodes: Array<Partner>;
};

export enum PublicationStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type Query = {
  __typename?: 'Query';
  article?: Maybe<Article>;
  articles: Array<Maybe<Article>>;
  articles_connection?: Maybe<ArticleEntityResponseCollection>;
  categories: Array<Maybe<Category>>;
  categories_connection?: Maybe<CategoryEntityResponseCollection>;
  category?: Maybe<Category>;
  event?: Maybe<Event>;
  eventCategories: Array<Maybe<EventCategory>>;
  eventCategories_connection?: Maybe<EventCategoryEntityResponseCollection>;
  eventCategory?: Maybe<EventCategory>;
  events: Array<Maybe<Event>>;
  events_connection?: Maybe<EventEntityResponseCollection>;
  global?: Maybe<Global>;
  heroCarousel?: Maybe<HeroCarousel>;
  heroLink?: Maybe<HeroLink>;
  heroLinks: Array<Maybe<HeroLink>>;
  heroLinks_connection?: Maybe<HeroLinkEntityResponseCollection>;
  i18NLocale?: Maybe<I18NLocale>;
  i18NLocales: Array<Maybe<I18NLocale>>;
  i18NLocales_connection?: Maybe<I18NLocaleEntityResponseCollection>;
  inspiration?: Maybe<Inspiration>;
  inspirationCategories: Array<Maybe<InspirationCategory>>;
  inspirationCategories_connection?: Maybe<InspirationCategoryEntityResponseCollection>;
  inspirationCategory?: Maybe<InspirationCategory>;
  inspirations: Array<Maybe<Inspiration>>;
  inspirations_connection?: Maybe<InspirationEntityResponseCollection>;
  job?: Maybe<Job>;
  jobs: Array<Maybe<Job>>;
  jobs_connection?: Maybe<JobEntityResponseCollection>;
  location?: Maybe<Location>;
  locations: Array<Maybe<Location>>;
  locations_connection?: Maybe<LocationEntityResponseCollection>;
  magazine?: Maybe<Magazine>;
  me?: Maybe<UsersPermissionsMe>;
  menuCategories: Array<Maybe<MenuCategory>>;
  menuCategories_connection?: Maybe<MenuCategoryEntityResponseCollection>;
  menuCategory?: Maybe<MenuCategory>;
  menuPage?: Maybe<MenuPage>;
  menuPages: Array<Maybe<MenuPage>>;
  menuPages_connection?: Maybe<MenuPageEntityResponseCollection>;
  partner?: Maybe<Partner>;
  partnerCategories: Array<Maybe<PartnerCategory>>;
  partnerCategories_connection?: Maybe<PartnerCategoryEntityResponseCollection>;
  partnerCategory?: Maybe<PartnerCategory>;
  partners: Array<Maybe<Partner>>;
  partners_connection?: Maybe<PartnerEntityResponseCollection>;
  reviewWorkflowsWorkflow?: Maybe<ReviewWorkflowsWorkflow>;
  reviewWorkflowsWorkflowStage?: Maybe<ReviewWorkflowsWorkflowStage>;
  reviewWorkflowsWorkflowStages: Array<Maybe<ReviewWorkflowsWorkflowStage>>;
  reviewWorkflowsWorkflowStages_connection?: Maybe<ReviewWorkflowsWorkflowStageEntityResponseCollection>;
  reviewWorkflowsWorkflows: Array<Maybe<ReviewWorkflowsWorkflow>>;
  reviewWorkflowsWorkflows_connection?: Maybe<ReviewWorkflowsWorkflowEntityResponseCollection>;
  uploadFile?: Maybe<UploadFile>;
  uploadFiles: Array<Maybe<UploadFile>>;
  uploadFiles_connection?: Maybe<UploadFileEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRole>;
  usersPermissionsRoles: Array<Maybe<UsersPermissionsRole>>;
  usersPermissionsRoles_connection?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUser>;
  usersPermissionsUsers: Array<Maybe<UsersPermissionsUser>>;
  usersPermissionsUsers_connection?: Maybe<UsersPermissionsUserEntityResponseCollection>;
};


export type QueryArticleArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryArticlesArgs = {
  filters?: InputMaybe<ArticleFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryArticles_ConnectionArgs = {
  filters?: InputMaybe<ArticleFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryCategoriesArgs = {
  filters?: InputMaybe<CategoryFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryCategories_ConnectionArgs = {
  filters?: InputMaybe<CategoryFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryCategoryArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryEventArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryEventCategoriesArgs = {
  filters?: InputMaybe<EventCategoryFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryEventCategories_ConnectionArgs = {
  filters?: InputMaybe<EventCategoryFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryEventCategoryArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryEventsArgs = {
  filters?: InputMaybe<EventFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryEvents_ConnectionArgs = {
  filters?: InputMaybe<EventFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryGlobalArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryHeroCarouselArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryHeroLinkArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryHeroLinksArgs = {
  filters?: InputMaybe<HeroLinkFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryHeroLinks_ConnectionArgs = {
  filters?: InputMaybe<HeroLinkFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryI18NLocaleArgs = {
  documentId: Scalars['ID'];
  status?: InputMaybe<PublicationStatus>;
};


export type QueryI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryI18NLocales_ConnectionArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryInspirationArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryInspirationCategoriesArgs = {
  filters?: InputMaybe<InspirationCategoryFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryInspirationCategories_ConnectionArgs = {
  filters?: InputMaybe<InspirationCategoryFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryInspirationCategoryArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryInspirationsArgs = {
  filters?: InputMaybe<InspirationFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryInspirations_ConnectionArgs = {
  filters?: InputMaybe<InspirationFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryJobArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryJobsArgs = {
  filters?: InputMaybe<JobFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryJobs_ConnectionArgs = {
  filters?: InputMaybe<JobFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryLocationArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryLocationsArgs = {
  filters?: InputMaybe<LocationFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryLocations_ConnectionArgs = {
  filters?: InputMaybe<LocationFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryMagazineArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryMenuCategoriesArgs = {
  filters?: InputMaybe<MenuCategoryFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryMenuCategories_ConnectionArgs = {
  filters?: InputMaybe<MenuCategoryFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryMenuCategoryArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryMenuPageArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryMenuPagesArgs = {
  filters?: InputMaybe<MenuPageFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryMenuPages_ConnectionArgs = {
  filters?: InputMaybe<MenuPageFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryPartnerArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryPartnerCategoriesArgs = {
  filters?: InputMaybe<PartnerCategoryFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryPartnerCategories_ConnectionArgs = {
  filters?: InputMaybe<PartnerCategoryFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryPartnerCategoryArgs = {
  documentId: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryPartnersArgs = {
  filters?: InputMaybe<PartnerFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryPartners_ConnectionArgs = {
  filters?: InputMaybe<PartnerFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryReviewWorkflowsWorkflowArgs = {
  documentId: Scalars['ID'];
  status?: InputMaybe<PublicationStatus>;
};


export type QueryReviewWorkflowsWorkflowStageArgs = {
  documentId: Scalars['ID'];
  status?: InputMaybe<PublicationStatus>;
};


export type QueryReviewWorkflowsWorkflowStagesArgs = {
  filters?: InputMaybe<ReviewWorkflowsWorkflowStageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryReviewWorkflowsWorkflowStages_ConnectionArgs = {
  filters?: InputMaybe<ReviewWorkflowsWorkflowStageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryReviewWorkflowsWorkflowsArgs = {
  filters?: InputMaybe<ReviewWorkflowsWorkflowFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryReviewWorkflowsWorkflows_ConnectionArgs = {
  filters?: InputMaybe<ReviewWorkflowsWorkflowFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryUploadFileArgs = {
  documentId: Scalars['ID'];
  status?: InputMaybe<PublicationStatus>;
};


export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryUploadFiles_ConnectionArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryUsersPermissionsRoleArgs = {
  documentId: Scalars['ID'];
  status?: InputMaybe<PublicationStatus>;
};


export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryUsersPermissionsRoles_ConnectionArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryUsersPermissionsUserArgs = {
  documentId: Scalars['ID'];
  status?: InputMaybe<PublicationStatus>;
};


export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};


export type QueryUsersPermissionsUsers_ConnectionArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<PublicationStatus>;
};

export type ReviewWorkflowsWorkflow = {
  __typename?: 'ReviewWorkflowsWorkflow';
  contentTypes: Scalars['JSON'];
  createdAt?: Maybe<Scalars['DateTime']>;
  documentId: Scalars['ID'];
  name: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  stageRequiredToPublish?: Maybe<ReviewWorkflowsWorkflowStage>;
  stages: Array<Maybe<ReviewWorkflowsWorkflowStage>>;
  stages_connection?: Maybe<ReviewWorkflowsWorkflowStageRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ReviewWorkflowsWorkflowStagesArgs = {
  filters?: InputMaybe<ReviewWorkflowsWorkflowStageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ReviewWorkflowsWorkflowStages_ConnectionArgs = {
  filters?: InputMaybe<ReviewWorkflowsWorkflowStageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ReviewWorkflowsWorkflowEntityResponseCollection = {
  __typename?: 'ReviewWorkflowsWorkflowEntityResponseCollection';
  nodes: Array<ReviewWorkflowsWorkflow>;
  pageInfo: Pagination;
};

export type ReviewWorkflowsWorkflowFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ReviewWorkflowsWorkflowFiltersInput>>>;
  contentTypes?: InputMaybe<JsonFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  documentId?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ReviewWorkflowsWorkflowFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ReviewWorkflowsWorkflowFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  stageRequiredToPublish?: InputMaybe<ReviewWorkflowsWorkflowStageFiltersInput>;
  stages?: InputMaybe<ReviewWorkflowsWorkflowStageFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ReviewWorkflowsWorkflowInput = {
  contentTypes?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  stageRequiredToPublish?: InputMaybe<Scalars['ID']>;
  stages?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type ReviewWorkflowsWorkflowStage = {
  __typename?: 'ReviewWorkflowsWorkflowStage';
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  documentId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workflow?: Maybe<ReviewWorkflowsWorkflow>;
};

export type ReviewWorkflowsWorkflowStageEntityResponseCollection = {
  __typename?: 'ReviewWorkflowsWorkflowStageEntityResponseCollection';
  nodes: Array<ReviewWorkflowsWorkflowStage>;
  pageInfo: Pagination;
};

export type ReviewWorkflowsWorkflowStageFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ReviewWorkflowsWorkflowStageFiltersInput>>>;
  color?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  documentId?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ReviewWorkflowsWorkflowStageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ReviewWorkflowsWorkflowStageFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  workflow?: InputMaybe<ReviewWorkflowsWorkflowFiltersInput>;
};

export type ReviewWorkflowsWorkflowStageInput = {
  color?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  workflow?: InputMaybe<Scalars['ID']>;
};

export type ReviewWorkflowsWorkflowStageRelationResponseCollection = {
  __typename?: 'ReviewWorkflowsWorkflowStageRelationResponseCollection';
  nodes: Array<ReviewWorkflowsWorkflowStage>;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contains?: InputMaybe<Scalars['String']>;
  containsi?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  eqi?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  nei?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<StringFilterInput>;
  notContains?: InputMaybe<Scalars['String']>;
  notContainsi?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  documentId: Scalars['ID'];
  ext?: Maybe<Scalars['String']>;
  formats?: Maybe<Scalars['JSON']>;
  hash: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  mime: Scalars['String'];
  name: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata?: Maybe<Scalars['JSON']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  nodes: Array<UploadFile>;
  pageInfo: Pagination;
};

export type UploadFileFiltersInput = {
  alternativeText?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  caption?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  documentId?: InputMaybe<IdFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFileFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  nodes: Array<UploadFile>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String'];
  password: Scalars['String'];
  provider?: Scalars['String'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  blocked?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  documentId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String'];
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  documentId: Scalars['ID'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UsersPermissionsRole>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsPermissionFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  documentId?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  nodes: Array<UsersPermissionsPermission>;
};

export type UsersPermissionsRegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  documentId: Scalars['ID'];
  name: Scalars['String'];
  permissions: Array<Maybe<UsersPermissionsPermission>>;
  permissions_connection?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users: Array<Maybe<UsersPermissionsUser>>;
  users_connection?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};


export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsRolePermissions_ConnectionArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsRoleUsers_ConnectionArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  nodes: Array<UsersPermissionsRole>;
  pageInfo: Pagination;
};

export type UsersPermissionsRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  documentId?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  blocked?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  documentId: Scalars['ID'];
  email: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UsersPermissionsRole>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUser>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  nodes: Array<UsersPermissionsUser>;
  pageInfo: Pagination;
};

export type UsersPermissionsUserFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  blocked?: InputMaybe<BooleanFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  documentId?: InputMaybe<IdFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  provider?: InputMaybe<StringFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  username?: InputMaybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
  blocked?: InputMaybe<Scalars['Boolean']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  role?: InputMaybe<Scalars['ID']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  nodes: Array<UsersPermissionsUser>;
};

export type GetArticlesQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  filters?: InputMaybe<ArticleFiltersInput>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetArticlesQuery = { __typename?: 'Query', articles: Array<{ __typename?: 'Article', documentId: string, description?: any | null, coverQuote?: string | null, slug: string, title: string, date?: any | null, cover?: { __typename?: 'UploadFile', url: string } | null } | null> };

export type GetArticlesInfoQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  filters?: InputMaybe<ArticleFiltersInput>;
}>;


export type GetArticlesInfoQuery = { __typename?: 'Query', articles: Array<{ __typename?: 'Article', coverQuote?: string | null, ctaFirstLink?: string | null, ctaSecondLink?: string | null, description?: any | null, documentId: string, locale?: string | null, slug: string, title: string, ctaFirstText?: string | null, ctaSecondText?: string | null, seoDescription?: string | null, seoOpenGraphDescription?: string | null, seoOpenGraphTitle?: string | null, seoTitle?: string | null, cover?: { __typename?: 'UploadFile', url: string } | null, images?: Array<{ __typename?: 'ComponentSharedSlider', files: Array<{ __typename?: 'UploadFile', url: string } | null> } | null> | null, localizations: Array<{ __typename?: 'Article', locale?: string | null, slug: string } | null>, seoOpenGraphImage?: { __typename?: 'UploadFile', url: string } | null } | null> };

export type GetContentQueryVariables = Exact<{
  articlesLocale?: InputMaybe<Scalars['I18NLocaleCode']>;
  eventsLocale?: InputMaybe<Scalars['I18NLocaleCode']>;
  partnersLocale?: InputMaybe<Scalars['I18NLocaleCode']>;
  menuPagesLocale?: InputMaybe<Scalars['I18NLocaleCode']>;
  articlesPagination?: InputMaybe<PaginationArg>;
  eventsPagination?: InputMaybe<PaginationArg>;
  menuPagesPagination?: InputMaybe<PaginationArg>;
  partnersPagination?: InputMaybe<PaginationArg>;
}>;


export type GetContentQuery = { __typename?: 'Query', articles: Array<{ __typename?: 'Article', documentId: string, title: string, slug: string, description?: any | null } | null>, events: Array<{ __typename?: 'Event', title?: string | null, slug: string, name: string, documentId: string, description?: any | null } | null>, menuPages: Array<{ __typename?: 'MenuPage', slug: string, title: string, documentId: string, description?: any | null } | null>, partners: Array<{ __typename?: 'Partner', documentId: string, description?: any | null, name: string, slug: string, title: string } | null> };

export type GetEventCategoriesQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;


export type GetEventCategoriesQuery = { __typename?: 'Query', eventCategories: Array<{ __typename?: 'EventCategory', documentId: string, name: string, slug: string } | null> };

export type GetEventInfoByIdQueryVariables = Exact<{
  documentId: Scalars['ID'];
}>;


export type GetEventInfoByIdQuery = { __typename?: 'Query', event?: { __typename?: 'Event', documentId: string, mailChimpTag?: string | null } | null };

export type GetEventsQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  filters?: InputMaybe<EventFiltersInput>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetEventsQuery = { __typename?: 'Query', events: Array<{ __typename?: 'Event', address?: string | null, dateEnd?: any | null, dateStart?: any | null, documentId: string, name: string, price?: number | null, slug: string, title?: string | null, cover?: { __typename?: 'UploadFile', url: string } | null, event_category?: { __typename?: 'EventCategory', name: string, slug: string } | null, logo?: { __typename?: 'UploadFile', url: string } | null } | null> };

export type GetEventsInfoQueryVariables = Exact<{
  filters?: InputMaybe<EventFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;


export type GetEventsInfoQuery = { __typename?: 'Query', events: Array<{ __typename?: 'Event', ctaFirstLink?: string | null, ctaFirstText?: string | null, ctaSecondLink?: string | null, ctaSecondText?: string | null, address?: string | null, dateEnd?: any | null, dateStart?: any | null, description?: any | null, documentId: string, name: string, price?: number | null, slug: string, mailChimpTag?: string | null, title?: string | null, seoDescription?: string | null, seoOpenGraphDescription?: string | null, seoOpenGraphTitle?: string | null, seoTitle?: string | null, cover?: { __typename?: 'UploadFile', url: string } | null, event_category?: { __typename?: 'EventCategory', name: string, slug: string } | null, images?: Array<{ __typename?: 'ComponentSharedSlider', files: Array<{ __typename?: 'UploadFile', url: string } | null> } | null> | null, logo?: { __typename?: 'UploadFile', url: string } | null, localizations: Array<{ __typename?: 'Event', slug: string, locale?: string | null } | null>, seoOpenGraphImage?: { __typename?: 'UploadFile', url: string } | null } | null> };

export type GetHeroCarouselQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;


export type GetHeroCarouselQuery = { __typename?: 'Query', heroCarousel?: { __typename?: 'HeroCarousel', documentId: string, images?: Array<{ __typename?: 'ComponentSharedSlider', files: Array<{ __typename?: 'UploadFile', url: string } | null> } | null> | null } | null };

export type GetHeroLinksQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;


export type GetHeroLinksQuery = { __typename?: 'Query', heroLinks: Array<{ __typename?: 'HeroLink', documentId: string, link: string, text: string, priority?: number | null } | null> };

export type GetInspirationCategoriesQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;


export type GetInspirationCategoriesQuery = { __typename?: 'Query', inspirationCategories: Array<{ __typename?: 'InspirationCategory', documentId: string, name: string, slug: string } | null> };

export type GetInspirationsQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  pagination?: InputMaybe<PaginationArg>;
  filters?: InputMaybe<InspirationFiltersInput>;
}>;


export type GetInspirationsQuery = { __typename?: 'Query', inspirations: Array<{ __typename?: 'Inspiration', documentId: string, description?: any | null, slug: string, title?: string | null, dateStart?: any | null, cover?: { __typename?: 'UploadFile', url: string } | null } | null> };

export type GetInspirationsInfoQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  filters?: InputMaybe<InspirationFiltersInput>;
}>;


export type GetInspirationsInfoQuery = { __typename?: 'Query', inspirations: Array<{ __typename?: 'Inspiration', ctaFirstLink?: string | null, ctaSecondLink?: string | null, description?: any | null, documentId: string, locale?: string | null, slug: string, title?: string | null, ctaFirstText?: string | null, ctaSecondText?: string | null, seoDescription?: string | null, seoOpenGraphDescription?: string | null, seoOpenGraphTitle?: string | null, seoTitle?: string | null, cover?: { __typename?: 'UploadFile', url: string } | null, images?: Array<{ __typename?: 'ComponentSharedSlider', files: Array<{ __typename?: 'UploadFile', url: string } | null> } | null> | null, localizations: Array<{ __typename?: 'Inspiration', locale?: string | null, slug: string } | null>, seoOpenGraphImage?: { __typename?: 'UploadFile', url: string } | null } | null> };

export type GetLocationsQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetLocationsQuery = { __typename?: 'Query', locations: Array<{ __typename?: 'Location', address: string, documentId: string, email?: string | null, phone?: string | null, location?: any | null, externalLink?: string | null, title?: string | null, externalLinkText?: string | null, zipcode?: string | null, cover?: { __typename?: 'UploadFile', url: string } | null, logo?: { __typename?: 'UploadFile', url: string } | null } | null> };

export type GetMagazineQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;


export type GetMagazineQuery = { __typename?: 'Query', magazine?: { __typename?: 'Magazine', description?: any | null, documentId: string, externalLink?: string | null, title: string, images?: Array<{ __typename?: 'ComponentSharedSlider', files: Array<{ __typename?: 'UploadFile', url: string } | null> } | null> | null } | null };

export type GetMenuCategoriesQueryVariables = Exact<{
  filters?: InputMaybe<MenuPageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  menuPagesPagination2?: InputMaybe<PaginationArg>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;


export type GetMenuCategoriesQuery = { __typename?: 'Query', menuCategories: Array<{ __typename?: 'MenuCategory', documentId: string, name: string, slug: string, priority?: number | null, menu_pages: Array<{ __typename?: 'MenuPage', slug: string, title: string, documentId: string, showInBurgerMenu?: boolean | null, showInBurgerMenuPriority?: number | null } | null> } | null> };

export type GetMenuPagesQueryVariables = Exact<{
  filters?: InputMaybe<MenuPageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;


export type GetMenuPagesQuery = { __typename?: 'Query', menuPages: Array<{ __typename?: 'MenuPage', documentId: string, slug: string, title: string, showInHeaderPriority?: number | null, showInFooterPriority?: number | null } | null> };

export type GetMenuPagesInfoQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  filters?: InputMaybe<MenuPageFiltersInput>;
}>;


export type GetMenuPagesInfoQuery = { __typename?: 'Query', menuPages: Array<{ __typename?: 'MenuPage', coverQuote?: string | null, ctaFirstLink?: string | null, ctaFirstText?: string | null, ctaSecondLink?: string | null, ctaSecondText?: string | null, description?: any | null, documentId: string, seoDescription?: string | null, seoOpenGraphDescription?: string | null, slug: string, seoTitle?: string | null, title: string, cover?: { __typename?: 'UploadFile', url: string } | null, images?: Array<{ __typename?: 'ComponentSharedSlider', files: Array<{ __typename?: 'UploadFile', url: string } | null> } | null> | null, seoOpenGraphImage?: { __typename?: 'UploadFile', url: string } | null, localizations: Array<{ __typename?: 'MenuPage', slug: string, locale?: string | null } | null> } | null> };

export type GetPartnerCategoriesQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;


export type GetPartnerCategoriesQuery = { __typename?: 'Query', partnerCategories: Array<{ __typename?: 'PartnerCategory', documentId: string, name: string, slug: string } | null> };

export type GetPartnersQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  filters?: InputMaybe<PartnerFiltersInput>;
}>;


export type GetPartnersQuery = { __typename?: 'Query', partners: Array<{ __typename?: 'Partner', name: string, coverQuote?: string | null, locale?: string | null, slug: string, documentId: string, title: string, date?: any | null, cover: { __typename?: 'UploadFile', url: string } } | null> };

export type GetPartnersInfoQueryVariables = Exact<{
  filters?: InputMaybe<PartnerFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
}>;


export type GetPartnersInfoQuery = { __typename?: 'Query', partners: Array<{ __typename?: 'Partner', coverQuote?: string | null, ctaFirstLink?: string | null, ctaSecondLink?: string | null, description?: any | null, documentId: string, slug: string, title: string, name: string, seoDescription?: string | null, seoOpenGraphDescription?: string | null, seoOpenGraphTitle?: string | null, seoTitle?: string | null, ctaFirstText?: string | null, ctaSecondText?: string | null, cover: { __typename?: 'UploadFile', url: string }, images?: Array<{ __typename?: 'ComponentSharedSlider', id: string, files: Array<{ __typename?: 'UploadFile', url: string } | null> } | null> | null, localizations: Array<{ __typename?: 'Partner', slug: string, locale?: string | null } | null>, seoOpenGraphImage?: { __typename?: 'UploadFile', url: string } | null } | null> };


export const GetArticlesDocument = gql`
    query GetArticles($locale: I18NLocaleCode, $filters: ArticleFiltersInput, $sort: [String]) {
  articles(locale: $locale, filters: $filters, sort: $sort) {
    documentId
    cover {
      url
    }
    description
    coverQuote
    slug
    title
    date
  }
}
    `;

/**
 * __useGetArticlesQuery__
 *
 * To run a query within a React component, call `useGetArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlesQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetArticlesQuery(baseOptions?: Apollo.QueryHookOptions<GetArticlesQuery, GetArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArticlesQuery, GetArticlesQueryVariables>(GetArticlesDocument, options);
      }
export function useGetArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticlesQuery, GetArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArticlesQuery, GetArticlesQueryVariables>(GetArticlesDocument, options);
        }
export type GetArticlesQueryHookResult = ReturnType<typeof useGetArticlesQuery>;
export type GetArticlesLazyQueryHookResult = ReturnType<typeof useGetArticlesLazyQuery>;
export type GetArticlesQueryResult = Apollo.QueryResult<GetArticlesQuery, GetArticlesQueryVariables>;
export const GetArticlesInfoDocument = gql`
    query getArticlesInfo($locale: I18NLocaleCode, $filters: ArticleFiltersInput) {
  articles(locale: $locale, filters: $filters) {
    cover {
      url
    }
    coverQuote
    ctaFirstLink
    ctaSecondLink
    description
    documentId
    images {
      files {
        url
      }
    }
    locale
    slug
    title
    localizations {
      locale
      slug
    }
    ctaFirstText
    ctaSecondText
    seoDescription
    seoOpenGraphDescription
    seoOpenGraphImage {
      url
    }
    seoOpenGraphTitle
    seoTitle
  }
}
    `;

/**
 * __useGetArticlesInfoQuery__
 *
 * To run a query within a React component, call `useGetArticlesInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlesInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlesInfoQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetArticlesInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetArticlesInfoQuery, GetArticlesInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArticlesInfoQuery, GetArticlesInfoQueryVariables>(GetArticlesInfoDocument, options);
      }
export function useGetArticlesInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticlesInfoQuery, GetArticlesInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArticlesInfoQuery, GetArticlesInfoQueryVariables>(GetArticlesInfoDocument, options);
        }
export type GetArticlesInfoQueryHookResult = ReturnType<typeof useGetArticlesInfoQuery>;
export type GetArticlesInfoLazyQueryHookResult = ReturnType<typeof useGetArticlesInfoLazyQuery>;
export type GetArticlesInfoQueryResult = Apollo.QueryResult<GetArticlesInfoQuery, GetArticlesInfoQueryVariables>;
export const GetContentDocument = gql`
    query GetContent($articlesLocale: I18NLocaleCode, $eventsLocale: I18NLocaleCode, $partnersLocale: I18NLocaleCode, $menuPagesLocale: I18NLocaleCode, $articlesPagination: PaginationArg, $eventsPagination: PaginationArg, $menuPagesPagination: PaginationArg, $partnersPagination: PaginationArg) {
  articles(locale: $articlesLocale, pagination: $articlesPagination) {
    documentId
    title
    slug
    description
  }
  events(locale: $eventsLocale, pagination: $eventsPagination) {
    title
    slug
    name
    documentId
    description
  }
  menuPages(locale: $menuPagesLocale, pagination: $menuPagesPagination) {
    slug
    title
    documentId
    description
  }
  partners(locale: $partnersLocale, pagination: $partnersPagination) {
    documentId
    description
    name
    slug
    title
  }
}
    `;

/**
 * __useGetContentQuery__
 *
 * To run a query within a React component, call `useGetContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentQuery({
 *   variables: {
 *      articlesLocale: // value for 'articlesLocale'
 *      eventsLocale: // value for 'eventsLocale'
 *      partnersLocale: // value for 'partnersLocale'
 *      menuPagesLocale: // value for 'menuPagesLocale'
 *      articlesPagination: // value for 'articlesPagination'
 *      eventsPagination: // value for 'eventsPagination'
 *      menuPagesPagination: // value for 'menuPagesPagination'
 *      partnersPagination: // value for 'partnersPagination'
 *   },
 * });
 */
export function useGetContentQuery(baseOptions?: Apollo.QueryHookOptions<GetContentQuery, GetContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentQuery, GetContentQueryVariables>(GetContentDocument, options);
      }
export function useGetContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentQuery, GetContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentQuery, GetContentQueryVariables>(GetContentDocument, options);
        }
export type GetContentQueryHookResult = ReturnType<typeof useGetContentQuery>;
export type GetContentLazyQueryHookResult = ReturnType<typeof useGetContentLazyQuery>;
export type GetContentQueryResult = Apollo.QueryResult<GetContentQuery, GetContentQueryVariables>;
export const GetEventCategoriesDocument = gql`
    query GetEventCategories($locale: I18NLocaleCode) {
  eventCategories(locale: $locale) {
    documentId
    name
    slug
  }
}
    `;

/**
 * __useGetEventCategoriesQuery__
 *
 * To run a query within a React component, call `useGetEventCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventCategoriesQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetEventCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetEventCategoriesQuery, GetEventCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventCategoriesQuery, GetEventCategoriesQueryVariables>(GetEventCategoriesDocument, options);
      }
export function useGetEventCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventCategoriesQuery, GetEventCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventCategoriesQuery, GetEventCategoriesQueryVariables>(GetEventCategoriesDocument, options);
        }
export type GetEventCategoriesQueryHookResult = ReturnType<typeof useGetEventCategoriesQuery>;
export type GetEventCategoriesLazyQueryHookResult = ReturnType<typeof useGetEventCategoriesLazyQuery>;
export type GetEventCategoriesQueryResult = Apollo.QueryResult<GetEventCategoriesQuery, GetEventCategoriesQueryVariables>;
export const GetEventInfoByIdDocument = gql`
    query GetEventInfoById($documentId: ID!) {
  event(documentId: $documentId) {
    documentId
    mailChimpTag
  }
}
    `;

/**
 * __useGetEventInfoByIdQuery__
 *
 * To run a query within a React component, call `useGetEventInfoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventInfoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventInfoByIdQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useGetEventInfoByIdQuery(baseOptions: Apollo.QueryHookOptions<GetEventInfoByIdQuery, GetEventInfoByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventInfoByIdQuery, GetEventInfoByIdQueryVariables>(GetEventInfoByIdDocument, options);
      }
export function useGetEventInfoByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventInfoByIdQuery, GetEventInfoByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventInfoByIdQuery, GetEventInfoByIdQueryVariables>(GetEventInfoByIdDocument, options);
        }
export type GetEventInfoByIdQueryHookResult = ReturnType<typeof useGetEventInfoByIdQuery>;
export type GetEventInfoByIdLazyQueryHookResult = ReturnType<typeof useGetEventInfoByIdLazyQuery>;
export type GetEventInfoByIdQueryResult = Apollo.QueryResult<GetEventInfoByIdQuery, GetEventInfoByIdQueryVariables>;
export const GetEventsDocument = gql`
    query GetEvents($locale: I18NLocaleCode, $filters: EventFiltersInput, $sort: [String]) {
  events(locale: $locale, filters: $filters, sort: $sort) {
    address
    cover {
      url
    }
    dateEnd
    dateStart
    documentId
    event_category {
      name
      slug
    }
    logo {
      url
    }
    name
    price
    slug
    title
  }
}
    `;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
      }
export function useGetEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
        }
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<typeof useGetEventsLazyQuery>;
export type GetEventsQueryResult = Apollo.QueryResult<GetEventsQuery, GetEventsQueryVariables>;
export const GetEventsInfoDocument = gql`
    query GetEventsInfo($filters: EventFiltersInput, $locale: I18NLocaleCode) {
  events(filters: $filters, locale: $locale) {
    cover {
      url
    }
    ctaFirstLink
    ctaFirstText
    ctaSecondLink
    ctaSecondText
    address
    dateEnd
    dateStart
    description
    documentId
    event_category {
      name
      slug
    }
    images {
      files {
        url
      }
    }
    logo {
      url
    }
    name
    price
    slug
    mailChimpTag
    title
    localizations {
      slug
      locale
    }
    seoDescription
    seoOpenGraphDescription
    seoOpenGraphImage {
      url
    }
    seoOpenGraphTitle
    seoTitle
  }
}
    `;

/**
 * __useGetEventsInfoQuery__
 *
 * To run a query within a React component, call `useGetEventsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsInfoQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetEventsInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetEventsInfoQuery, GetEventsInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventsInfoQuery, GetEventsInfoQueryVariables>(GetEventsInfoDocument, options);
      }
export function useGetEventsInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventsInfoQuery, GetEventsInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventsInfoQuery, GetEventsInfoQueryVariables>(GetEventsInfoDocument, options);
        }
export type GetEventsInfoQueryHookResult = ReturnType<typeof useGetEventsInfoQuery>;
export type GetEventsInfoLazyQueryHookResult = ReturnType<typeof useGetEventsInfoLazyQuery>;
export type GetEventsInfoQueryResult = Apollo.QueryResult<GetEventsInfoQuery, GetEventsInfoQueryVariables>;
export const GetHeroCarouselDocument = gql`
    query GetHeroCarousel($locale: I18NLocaleCode) {
  heroCarousel(locale: $locale) {
    documentId
    images {
      files {
        url
      }
    }
  }
}
    `;

/**
 * __useGetHeroCarouselQuery__
 *
 * To run a query within a React component, call `useGetHeroCarouselQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHeroCarouselQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHeroCarouselQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetHeroCarouselQuery(baseOptions?: Apollo.QueryHookOptions<GetHeroCarouselQuery, GetHeroCarouselQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHeroCarouselQuery, GetHeroCarouselQueryVariables>(GetHeroCarouselDocument, options);
      }
export function useGetHeroCarouselLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHeroCarouselQuery, GetHeroCarouselQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHeroCarouselQuery, GetHeroCarouselQueryVariables>(GetHeroCarouselDocument, options);
        }
export type GetHeroCarouselQueryHookResult = ReturnType<typeof useGetHeroCarouselQuery>;
export type GetHeroCarouselLazyQueryHookResult = ReturnType<typeof useGetHeroCarouselLazyQuery>;
export type GetHeroCarouselQueryResult = Apollo.QueryResult<GetHeroCarouselQuery, GetHeroCarouselQueryVariables>;
export const GetHeroLinksDocument = gql`
    query GetHeroLinks($locale: I18NLocaleCode) {
  heroLinks(locale: $locale, sort: "priority:asc") {
    documentId
    link
    text
    priority
  }
}
    `;

/**
 * __useGetHeroLinksQuery__
 *
 * To run a query within a React component, call `useGetHeroLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHeroLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHeroLinksQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetHeroLinksQuery(baseOptions?: Apollo.QueryHookOptions<GetHeroLinksQuery, GetHeroLinksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHeroLinksQuery, GetHeroLinksQueryVariables>(GetHeroLinksDocument, options);
      }
export function useGetHeroLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHeroLinksQuery, GetHeroLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHeroLinksQuery, GetHeroLinksQueryVariables>(GetHeroLinksDocument, options);
        }
export type GetHeroLinksQueryHookResult = ReturnType<typeof useGetHeroLinksQuery>;
export type GetHeroLinksLazyQueryHookResult = ReturnType<typeof useGetHeroLinksLazyQuery>;
export type GetHeroLinksQueryResult = Apollo.QueryResult<GetHeroLinksQuery, GetHeroLinksQueryVariables>;
export const GetInspirationCategoriesDocument = gql`
    query getInspirationCategories($locale: I18NLocaleCode) {
  inspirationCategories(locale: $locale) {
    documentId
    name
    slug
  }
}
    `;

/**
 * __useGetInspirationCategoriesQuery__
 *
 * To run a query within a React component, call `useGetInspirationCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInspirationCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInspirationCategoriesQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetInspirationCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetInspirationCategoriesQuery, GetInspirationCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInspirationCategoriesQuery, GetInspirationCategoriesQueryVariables>(GetInspirationCategoriesDocument, options);
      }
export function useGetInspirationCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInspirationCategoriesQuery, GetInspirationCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInspirationCategoriesQuery, GetInspirationCategoriesQueryVariables>(GetInspirationCategoriesDocument, options);
        }
export type GetInspirationCategoriesQueryHookResult = ReturnType<typeof useGetInspirationCategoriesQuery>;
export type GetInspirationCategoriesLazyQueryHookResult = ReturnType<typeof useGetInspirationCategoriesLazyQuery>;
export type GetInspirationCategoriesQueryResult = Apollo.QueryResult<GetInspirationCategoriesQuery, GetInspirationCategoriesQueryVariables>;
export const GetInspirationsDocument = gql`
    query GetInspirations($locale: I18NLocaleCode, $sort: [String], $pagination: PaginationArg, $filters: InspirationFiltersInput) {
  inspirations(
    locale: $locale
    sort: $sort
    pagination: $pagination
    filters: $filters
  ) {
    documentId
    cover {
      url
    }
    description
    slug
    title
    dateStart
  }
}
    `;

/**
 * __useGetInspirationsQuery__
 *
 * To run a query within a React component, call `useGetInspirationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInspirationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInspirationsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetInspirationsQuery(baseOptions?: Apollo.QueryHookOptions<GetInspirationsQuery, GetInspirationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInspirationsQuery, GetInspirationsQueryVariables>(GetInspirationsDocument, options);
      }
export function useGetInspirationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInspirationsQuery, GetInspirationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInspirationsQuery, GetInspirationsQueryVariables>(GetInspirationsDocument, options);
        }
export type GetInspirationsQueryHookResult = ReturnType<typeof useGetInspirationsQuery>;
export type GetInspirationsLazyQueryHookResult = ReturnType<typeof useGetInspirationsLazyQuery>;
export type GetInspirationsQueryResult = Apollo.QueryResult<GetInspirationsQuery, GetInspirationsQueryVariables>;
export const GetInspirationsInfoDocument = gql`
    query GetInspirationsInfo($locale: I18NLocaleCode, $filters: InspirationFiltersInput) {
  inspirations(locale: $locale, filters: $filters) {
    cover {
      url
    }
    ctaFirstLink
    ctaSecondLink
    description
    documentId
    images {
      files {
        url
      }
    }
    locale
    slug
    title
    localizations {
      locale
      slug
    }
    ctaFirstText
    ctaSecondText
    seoDescription
    seoOpenGraphDescription
    seoOpenGraphImage {
      url
    }
    seoOpenGraphTitle
    seoTitle
  }
}
    `;

/**
 * __useGetInspirationsInfoQuery__
 *
 * To run a query within a React component, call `useGetInspirationsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInspirationsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInspirationsInfoQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetInspirationsInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetInspirationsInfoQuery, GetInspirationsInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInspirationsInfoQuery, GetInspirationsInfoQueryVariables>(GetInspirationsInfoDocument, options);
      }
export function useGetInspirationsInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInspirationsInfoQuery, GetInspirationsInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInspirationsInfoQuery, GetInspirationsInfoQueryVariables>(GetInspirationsInfoDocument, options);
        }
export type GetInspirationsInfoQueryHookResult = ReturnType<typeof useGetInspirationsInfoQuery>;
export type GetInspirationsInfoLazyQueryHookResult = ReturnType<typeof useGetInspirationsInfoLazyQuery>;
export type GetInspirationsInfoQueryResult = Apollo.QueryResult<GetInspirationsInfoQuery, GetInspirationsInfoQueryVariables>;
export const GetLocationsDocument = gql`
    query GetLocations($pagination: PaginationArg, $sort: [String]) {
  locations(pagination: $pagination, sort: $sort) {
    cover {
      url
    }
    address
    documentId
    email
    logo {
      url
    }
    phone
    location
    externalLink
    title
    externalLinkText
    zipcode
  }
}
    `;

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
      }
export function useGetLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
        }
export type GetLocationsQueryHookResult = ReturnType<typeof useGetLocationsQuery>;
export type GetLocationsLazyQueryHookResult = ReturnType<typeof useGetLocationsLazyQuery>;
export type GetLocationsQueryResult = Apollo.QueryResult<GetLocationsQuery, GetLocationsQueryVariables>;
export const GetMagazineDocument = gql`
    query GetMagazine($locale: I18NLocaleCode) {
  magazine(locale: $locale) {
    description
    documentId
    externalLink
    images {
      files {
        url
      }
    }
    title
  }
}
    `;

/**
 * __useGetMagazineQuery__
 *
 * To run a query within a React component, call `useGetMagazineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMagazineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMagazineQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetMagazineQuery(baseOptions?: Apollo.QueryHookOptions<GetMagazineQuery, GetMagazineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMagazineQuery, GetMagazineQueryVariables>(GetMagazineDocument, options);
      }
export function useGetMagazineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMagazineQuery, GetMagazineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMagazineQuery, GetMagazineQueryVariables>(GetMagazineDocument, options);
        }
export type GetMagazineQueryHookResult = ReturnType<typeof useGetMagazineQuery>;
export type GetMagazineLazyQueryHookResult = ReturnType<typeof useGetMagazineLazyQuery>;
export type GetMagazineQueryResult = Apollo.QueryResult<GetMagazineQuery, GetMagazineQueryVariables>;
export const GetMenuCategoriesDocument = gql`
    query GetMenuCategories($filters: MenuPageFiltersInput, $pagination: PaginationArg, $menuPagesPagination2: PaginationArg, $locale: I18NLocaleCode) {
  menuCategories(pagination: $pagination, locale: $locale) {
    documentId
    name
    slug
    priority
    menu_pages(filters: $filters, pagination: $menuPagesPagination2) {
      slug
      title
      documentId
      showInBurgerMenu
      showInBurgerMenuPriority
    }
  }
}
    `;

/**
 * __useGetMenuCategoriesQuery__
 *
 * To run a query within a React component, call `useGetMenuCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuCategoriesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      menuPagesPagination2: // value for 'menuPagesPagination2'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetMenuCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetMenuCategoriesQuery, GetMenuCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuCategoriesQuery, GetMenuCategoriesQueryVariables>(GetMenuCategoriesDocument, options);
      }
export function useGetMenuCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuCategoriesQuery, GetMenuCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuCategoriesQuery, GetMenuCategoriesQueryVariables>(GetMenuCategoriesDocument, options);
        }
export type GetMenuCategoriesQueryHookResult = ReturnType<typeof useGetMenuCategoriesQuery>;
export type GetMenuCategoriesLazyQueryHookResult = ReturnType<typeof useGetMenuCategoriesLazyQuery>;
export type GetMenuCategoriesQueryResult = Apollo.QueryResult<GetMenuCategoriesQuery, GetMenuCategoriesQueryVariables>;
export const GetMenuPagesDocument = gql`
    query GetMenuPages($filters: MenuPageFiltersInput, $pagination: PaginationArg, $locale: I18NLocaleCode) {
  menuPages(filters: $filters, pagination: $pagination, locale: $locale) {
    documentId
    slug
    title
    showInHeaderPriority
    showInFooterPriority
  }
}
    `;

/**
 * __useGetMenuPagesQuery__
 *
 * To run a query within a React component, call `useGetMenuPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuPagesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetMenuPagesQuery(baseOptions?: Apollo.QueryHookOptions<GetMenuPagesQuery, GetMenuPagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuPagesQuery, GetMenuPagesQueryVariables>(GetMenuPagesDocument, options);
      }
export function useGetMenuPagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuPagesQuery, GetMenuPagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuPagesQuery, GetMenuPagesQueryVariables>(GetMenuPagesDocument, options);
        }
export type GetMenuPagesQueryHookResult = ReturnType<typeof useGetMenuPagesQuery>;
export type GetMenuPagesLazyQueryHookResult = ReturnType<typeof useGetMenuPagesLazyQuery>;
export type GetMenuPagesQueryResult = Apollo.QueryResult<GetMenuPagesQuery, GetMenuPagesQueryVariables>;
export const GetMenuPagesInfoDocument = gql`
    query GetMenuPagesInfo($locale: I18NLocaleCode, $filters: MenuPageFiltersInput) {
  menuPages(locale: $locale, filters: $filters) {
    cover {
      url
    }
    coverQuote
    ctaFirstLink
    ctaFirstText
    ctaSecondLink
    ctaSecondText
    description
    documentId
    images {
      files {
        url
      }
    }
    seoDescription
    seoOpenGraphDescription
    seoOpenGraphImage {
      url
    }
    slug
    seoTitle
    title
    localizations {
      slug
      locale
    }
  }
}
    `;

/**
 * __useGetMenuPagesInfoQuery__
 *
 * To run a query within a React component, call `useGetMenuPagesInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuPagesInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuPagesInfoQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetMenuPagesInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetMenuPagesInfoQuery, GetMenuPagesInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuPagesInfoQuery, GetMenuPagesInfoQueryVariables>(GetMenuPagesInfoDocument, options);
      }
export function useGetMenuPagesInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuPagesInfoQuery, GetMenuPagesInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuPagesInfoQuery, GetMenuPagesInfoQueryVariables>(GetMenuPagesInfoDocument, options);
        }
export type GetMenuPagesInfoQueryHookResult = ReturnType<typeof useGetMenuPagesInfoQuery>;
export type GetMenuPagesInfoLazyQueryHookResult = ReturnType<typeof useGetMenuPagesInfoLazyQuery>;
export type GetMenuPagesInfoQueryResult = Apollo.QueryResult<GetMenuPagesInfoQuery, GetMenuPagesInfoQueryVariables>;
export const GetPartnerCategoriesDocument = gql`
    query GetPartnerCategories($locale: I18NLocaleCode) {
  partnerCategories(locale: $locale) {
    documentId
    name
    slug
  }
}
    `;

/**
 * __useGetPartnerCategoriesQuery__
 *
 * To run a query within a React component, call `useGetPartnerCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerCategoriesQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetPartnerCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetPartnerCategoriesQuery, GetPartnerCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartnerCategoriesQuery, GetPartnerCategoriesQueryVariables>(GetPartnerCategoriesDocument, options);
      }
export function useGetPartnerCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerCategoriesQuery, GetPartnerCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartnerCategoriesQuery, GetPartnerCategoriesQueryVariables>(GetPartnerCategoriesDocument, options);
        }
export type GetPartnerCategoriesQueryHookResult = ReturnType<typeof useGetPartnerCategoriesQuery>;
export type GetPartnerCategoriesLazyQueryHookResult = ReturnType<typeof useGetPartnerCategoriesLazyQuery>;
export type GetPartnerCategoriesQueryResult = Apollo.QueryResult<GetPartnerCategoriesQuery, GetPartnerCategoriesQueryVariables>;
export const GetPartnersDocument = gql`
    query GetPartners($locale: I18NLocaleCode, $sort: [String], $filters: PartnerFiltersInput) {
  partners(locale: $locale, sort: $sort, filters: $filters) {
    cover {
      url
    }
    name
    coverQuote
    locale
    slug
    documentId
    title
    date
  }
}
    `;

/**
 * __useGetPartnersQuery__
 *
 * To run a query within a React component, call `useGetPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnersQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetPartnersQuery(baseOptions?: Apollo.QueryHookOptions<GetPartnersQuery, GetPartnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartnersQuery, GetPartnersQueryVariables>(GetPartnersDocument, options);
      }
export function useGetPartnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnersQuery, GetPartnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartnersQuery, GetPartnersQueryVariables>(GetPartnersDocument, options);
        }
export type GetPartnersQueryHookResult = ReturnType<typeof useGetPartnersQuery>;
export type GetPartnersLazyQueryHookResult = ReturnType<typeof useGetPartnersLazyQuery>;
export type GetPartnersQueryResult = Apollo.QueryResult<GetPartnersQuery, GetPartnersQueryVariables>;
export const GetPartnersInfoDocument = gql`
    query GetPartnersInfo($filters: PartnerFiltersInput, $locale: I18NLocaleCode) {
  partners(filters: $filters, locale: $locale) {
    cover {
      url
    }
    coverQuote
    ctaFirstLink
    ctaSecondLink
    description
    documentId
    images {
      id
      files {
        url
      }
    }
    slug
    title
    name
    localizations {
      slug
      locale
    }
    seoDescription
    seoOpenGraphDescription
    seoOpenGraphImage {
      url
    }
    seoOpenGraphTitle
    seoTitle
    ctaFirstText
    ctaSecondText
  }
}
    `;

/**
 * __useGetPartnersInfoQuery__
 *
 * To run a query within a React component, call `useGetPartnersInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnersInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnersInfoQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetPartnersInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetPartnersInfoQuery, GetPartnersInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPartnersInfoQuery, GetPartnersInfoQueryVariables>(GetPartnersInfoDocument, options);
      }
export function useGetPartnersInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnersInfoQuery, GetPartnersInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPartnersInfoQuery, GetPartnersInfoQueryVariables>(GetPartnersInfoDocument, options);
        }
export type GetPartnersInfoQueryHookResult = ReturnType<typeof useGetPartnersInfoQuery>;
export type GetPartnersInfoLazyQueryHookResult = ReturnType<typeof useGetPartnersInfoLazyQuery>;
export type GetPartnersInfoQueryResult = Apollo.QueryResult<GetPartnersInfoQuery, GetPartnersInfoQueryVariables>;