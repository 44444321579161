import { matchSorter } from "match-sorter";
import { useEffect, useMemo, useState } from "react";
import { Button, InputGroup } from "react-bootstrap";
import CloseButton from "react-bootstrap/CloseButton";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { IoSearchOutline } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";
import { PAGES } from "../../../constants/pages";
import {
  useGetArticlesQuery,
  useGetContentQuery,
} from "../../../generated/graphql";
import { findMatchingPages } from "../../../utils/helpers";
import SlidePanelWrapper from "../../hocs/SlidePanel";
import "./SearchBar.scss";

type SearchBarProps = {
  isShowSearch: boolean;
  toggleSearchModal: () => void;
  isShowMenu: boolean;
  toggleMenuModal: () => void;
};
export function SearchBar({
  isShowSearch,
  toggleSearchModal,
  isShowMenu,
  toggleMenuModal,
}: SearchBarProps) {
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const { data } = useGetArticlesQuery({
    fetchPolicy: "cache-first",
    variables: {
      locale: i18n.language,
      sort: "date:desc",
      filters: {
        category: {
          name: {
            eq: "Inspirations",
          },
        },
      },
    },
  });
  const { data: contentData } = useGetContentQuery({
    fetchPolicy: "cache-first",
    variables: {
      articlesLocale: i18n.language,
      eventsLocale: i18n.language,
      partnersLocale: i18n.language,
      menuPagesLocale: i18n.language,
      articlesPagination: { limit: -1 },
      partnersPagination: { limit: -1 },
      eventsPagination: { limit: -1 },
      menuPagesPagination: { limit: -1 },
    },
  });

  const articles = useMemo(() => {
    let articles = data?.articles?.slice(0, 5) ?? [];
    return articles;
  }, [data?.articles]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  useEffect(() => {
    setSearchValue(""); // reset when navigate to another page
  }, [pathname]);

  const handleClose = () => {
    toggleSearchModal();
    setSearchValue("");
  };

  const filteredPages = useMemo(() => {
    let matchingPages = findMatchingPages(
      contentData,
      debouncedSearchValue,
      i18n.language
    );

    const sortedPages = matchSorter(matchingPages, debouncedSearchValue, {
      keys: ["name"],
    });

    return debouncedSearchValue.length > 2
      ? [
          ...sortedPages,
          ...matchingPages.filter((page) => !sortedPages.includes(page)),
        ]
      : [];
  }, [contentData, debouncedSearchValue, i18n.language]);

  return (
    <SlidePanelWrapper showPanel={isShowSearch}>
      <Form
        onSubmit={(e) => e.preventDefault()}
        className={"search-form h-full"}
      >
        <CloseButton
          className="absolute right-2.5 top-2.5 p-2.5"
          onClick={handleClose}
        />
        <InputGroup className="searchContainer">
          <Form.Control
            id="search"
            type="search"
            placeholder="Search"
            className=" mr-sm-2 searchInput"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Button variant={"light"}>
            <IoSearchOutline />
          </Button>
        </InputGroup>

        {debouncedSearchValue.length > 2 ? (
          <>
            {filteredPages.length > 0 ? (
              <ul className={"flex flex-col h-[75%] overflow-y-scroll"}>
                {filteredPages.map((item) => (
                  <li key={item.name} className="mb-3">
                    <Link
                      className="text-black font-medium no-underline hover:underline text-lg"
                      to={item.slug ?? ""}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="font-medium font-matter">
                Not found information about: {debouncedSearchValue}
              </p>
            )}
          </>
        ) : (
          <ul>
            {/* BY DEFAULT - SHOW 5 ARTICLES IN CATEGORY 'INSPIRATIONS' */}
            {articles.map((article) => {
              return (
                <li key={article?.title} className="mb-3">
                  <Link
                    className="text-black font-medium no-underline hover:underline text-lg"
                    to={`${PAGES.ARTICLE}/${i18n.language}/${article?.slug}`}
                  >
                    {article?.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </Form>
    </SlidePanelWrapper>
  );
}
