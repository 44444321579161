import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { i18nextPlugin } from "translation-check";

export const APP_LOCALES = process.env.REACT_APP_LOCALES
  ? process.env.REACT_APP_LOCALES.split(",")
  : ["nl"];
export const APP_DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LOCALE ?? "nl";

i18n
  // detect user language
  .use(LanguageDetector)
  //By using 'initReactI18next', we pass the i18n instance to react-i18next which will make it available for all the components.
  .use(initReactI18next)
  .use(i18nextPlugin)
  .use(Backend)
  .init({
    supportedLngs: APP_LOCALES,
    fallbackLng: APP_DEFAULT_LOCALE,
    debug: false,
    detection: {
      order: [
        "querystring",
        "cookie",
        "navigator",
        "htmlTag",
        "localStorage",
        "path",
        "subdomain",
      ],
      caches: ["cookie"],
      lookupQuerystring: "lang",
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: ["translation"],
    defaultNS: "translation",
    nsSeparator: ":",
    saveMissing: false,
  });

i18n.services.formatter.add("lowercase", (value, lng, options) => {
  return value.toLowerCase();
});

export default i18n;
