import React from "react";
import { cn } from "../../../utils";

interface SectionWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

function SectionWrapper({ children, className }: SectionWrapperProps) {
  return (
    <div
      className={cn(
        "flex flex-col-reverse lg:flex-row justify-between gap-x-20 lg:h-[calc(100vh-290px)] pb-3",
        className
      )}
    >
      {children}
    </div>
  );
}

export default SectionWrapper;
